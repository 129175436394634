import axios from "axios";

axios.defaults.headers = {
    'Authorization':'Bearer ' + localStorage.getItem('token'),
    'Accept':'application/json',
}

const state = {
    callAnswer: null,
}

const getters = {
    callAnswer: state => state.callAnswer,
}

const actions = {

    getBinotelInfo: async () => {
        try{
            const result = await { result: true }
            console.log('result - ', result);
        }catch(e){
            console.log('e - ', e)
        }
    },
    createCall: async ({commit}, payload) => {
        try{
            const result = await axios.post('createCall', payload)
            commit('getCallAnswer', result.data);
            return{
                status:true,
                result:result.data
            }
        }catch(e){
            return{
                status:false,
                result:e.response.data
            }
        }
    },
}

const mutations = {
    getCallAnswer(state, result){
        state.callAnswer = result
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
