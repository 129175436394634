<template>
  <div>
    <Header/>
    <leftBar/>

    <div class="content"
         :class="{show:menuHide}"
    >
      <div class="phone-orders__empty" v-if="!toggleEmpty">
        По данному запиту нічого не знайдено
      </div>
      <div class="phone-orders" v-else>
        <table class="phone-orders__list" v-if="orderList">
          <tr>
            <th>Імя</th>
            <th>Статус</th>
            <th>Телефон</th>
            <th>Замовлення №</th>
            <th>Сума</th>
            <th>Дата</th>
          </tr>
          <tr class="phone-orders__item mb-2"
              v-for="(item, index) in orderList"
              :key="index">
            <td> <span class="mr-3"><i class="fa fa-male"></i> {{item.customer_name}}</span></td>
            <td><div class="phone-orders__status" :style="'display: inline-block; background: '+item.order_status.color" v-if="item.order_status">{{item.order_status.title}}</div></td>
            <td><span class="mr-3"><i class="fa fa-phone"></i> {{item.phone}}</span></td>
            <td> {{item.order_id}}[{{item.id}}],</td>
            <td><span class="mr-3"><i class="fa fa-money"></i> {{item.price}}</span></td>
            <td><span class="mr-3"><i class="fa fa-calendar"></i> {{$moment(item.created_at).format('DD.MM.YYYY HH:mm:ss')}}</span></td>
            <td><span class="mr-3"><i class="fa fa-calendar"></i> {{$moment(item.created_at).format('DD.MM.YYYY HH:mm:ss')}}</span></td>
            <td>

              <b-button
                  class="auth__btn p-1 pl-4 pr-4 active"
                  variant="primary"
                  @click="goToOrder(item.id)"
              >Перейти до замовлення
              </b-button>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapMutations, mapGetters} from "vuex";

const _ = require('lodash')

export default {
  components:{
    Header: () => import('@/components/header/index.vue'),
    leftBar: () => import('@/components/left-bar/index.vue'),
  },
  data(){
    return{
      phoneNumber: null,
      orderList: null,
      toggleEmpty: false,
    }
  },
  computed:{
    ...mapGetters({
      ordersPhoneList: 'orders/ordersPhoneList',
      orderStatusList:'orders/orderStatusList',
      menuHide:'config/menuHide',
    })
  },
  watch:{
    orderList(e){
      if(e.length) {
        e.map(item => {
          let price = 0;
          item.order_products.map(productItem => {
            price += productItem.price * productItem.quantity;
          })
          let orderStatus = this.orderStatusList.data.filter(itemStatus => itemStatus.id === item.order_status_id);
          orderStatus = orderStatus[0];
          item.order_status = orderStatus
          item.price = price
        })
        this.toggleEmpty = true;
      }
    },
  },
  created() {
    this.toggleLoadingStatus(true)
    this.phoneNumber = this.$route.params.id;
    if(this.phoneNumber && this.phoneNumber.length > 1){
      this.getOrderStatuses().then(()=>{
        this.getOrdersForPhone({phone: this.phoneNumber}).then(()=>{
          this.toggleLoadingStatus(false)
          this.orderList = _.clone(this.ordersPhoneList.data);
        })
      })
    }
  },
  methods:{
    goToOrder(orderId){
      this.$router.push({name:'ordersDragList', params:{id: orderId}})
    },
    ...mapActions({
      getOrdersForPhone: 'orders/getOrdersForPhone',
      getOrderStatuses:'orders/getOrderStatuses',
    }),
    ...mapMutations({
      toggleLoadingStatus: 'config/toggleLoadingStatus',
    })
  }
}
</script>
<style lang="scss" scoped>
.phone-orders{
  padding-top: 50px;
  &__empty{
    padding: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    width: 80vw;
    font-size: 24px;
    letter-spacing: 1.5px;
  }
}
</style>
