import { mapGetters, mapActions, mapMutations } from "vuex";
import filterSearch from "@/mixins/filter-search/index.js";
import showPage from "../../../mixins/showPage";

const _ = require("lodash");

export default {
  components: {
    Header: () => import("@/components/header/index.vue"),
    leftBar: () => import("@/components/left-bar/index.vue"),
    DragList: () => import("@/components/DragList/index.vue"),
    TableOptions: () => import("@/components/table/options/index.vue"),
    Table: () => import("@/components/table/list/index.vue"),
    popup: () => import("@/components/popups/orders/order/index.vue"),
    popupTtn: () => import("@/components/popups/orders/np-ttn/index.vue"),
    popupRemember: () => import("@/components/popups/remember/index.vue"),
    popupAddProduct: () =>
      import("@/components/popups/orders/add-product/index.vue"),
    popupProduct: () => import("@/components/popups/catalog/product/index.vue"),
    massagePopup: () => import("@/components/popups/sendMassage/index.vue"),
    callPopup: () => import("@/components/popups/callPopup/index.vue"),
    phoneOrdersPopup: () => import("@/components/popups/phoneOrders/index.vue"),
    popupInfoTtn: () => import("@/components/popups/orders/ttn-info/index.vue"),
    confirmUnitePopup: () =>
      import("@/components/popups/uniteConfirm/index.vue"),
    ttnNotification: () =>
      import("@/components/popups/orders/ttn-notification/index.vue"),
    carousel: () => import("vue-owl-carousel"),
  },
  mixins: [filterSearch, showPage],
  data() {
    return {
      pageId: 10,
      statusIdNewList: [],
      ttnNotificationStatus: false,
      changePosition: false,
      activeStatus: 0,
      fields: [
        {
          key: "id",
          label: "id",
        },
        {
          key: "order_id",
          label: "order_id",
        },
        {
          key: "customer_name",
          label: "Покупатель",
        },
        {
          key: "order_phone",
          label: "Телефон",
        },
        {
          key: "comment",
          label: "Комментарий",
        },
        {
          key: "order_value",
          label: "сумма",
        },
        {
          key: "product",
          label: "Товар",
        },
        {
          key: "payment_type",
          label: "Оплата",
        },
        {
          key: "shipment_type",
          label: "Тип доставки",
        },
        {
          key: "address",
          label: "Адрес доставки",
        },
        {
          key: "ttn",
          label: "TTH",
        },
        {
          key: "sent",
          label: "Отправлено",
        },
        {
          key: "ttn_status",
          label: "TTH статус",
        },
        {
          key: "created_at",
          label: "Добавлено",
        },
        {
          key: "employer",
          label: "Сотрудник",
        },
        {
          key: "department",
          label: "Отдел",
        },
        {
          key: "updated_at",
          label: "Обновлено",
        },
        {
          key: "site",
          label: "Cайт",
        },
        {
          key: "product_status",
          label: "Статус",
        },
        {
          key: "delivered",
          label: "Сдано",
        },
        {
          key: "utm_source",
          label: "utm_source",
        },
        {
          key: "utm_medium",
          label: "utm_medium",
        },
        {
          key: "utm_campaign",
          label: "utm_campaign",
        },
        {
          key: "userIp",
          label: "ip",
        },
      ],
      items: [],
      selectedItem: null,
      showPopup: false,
      showPopupTtn: false,
      showPopupRemember: false,
      orderData: null,
      showProductPopup: false,
      showPopupInfoTtn: false,
      showMassagePopup: false,
      showCallPopup: false,
      showPhoneOrdersPopup: false,
      showConfirmUnitePopup: false,
      showPopupTtnNotification: false,
      newProduct: null,
      filterData: {
        type: null,
        query: null,
      },
      statusesList: null,
      hidePhonesInOrders: false,
      showStatusesList: false,
      callForm: null,
      orderDataOrder_id: null,
      callOrderId: null,
      orderDepartmentId: null,
    };
  },
  computed: {
    ...mapGetters({
      order: "orders/order",
      users: "contacts/users",
      profile: "profile/profile",
      menuHide: "config/menuHide",
      orderAll: "orders/orderAll",
      ordersList: "orders/ordersList",
      searchQuery: "orders/searchQuery",
      usersGroup: "contacts/usersGroup",
      departments: "catalog/departments",
      oneOrderList: "orders/oneOrderList",
      operatorIcons: "config/operatorIcons",
      ordersNewList: "orders/ordersNewList",
      paymentMethods: "orders/paymentMethods",
      orderStatusList: "orders/orderStatusList",
      shipmentMethods: "orders/shipmentMethods",
      ttnNotification: "orders/ttnNotification",
      showAddProductPopUp: "config/showAddProductPopUp",
    }),
  },
  watch: {
    ttnNotification(e) {
      this.showPopupTtnNotification = true;
      setTimeout(() => {
        if (!e.models.length) {
          this.showPopupTtnNotification = false;
        }
      }, 500);
    },
    usersGroup(e) {
      if (this.profile && this.profile.user_group_id) {
        let userGroup = e.data.filter(
          (item) => item.id === this.profile.user_group_id
        );
        userGroup = userGroup[0];
        this.hidePhonesInOrders = userGroup.hide_phones_in_orders;
        this.statusesList = userGroup.accept_to_statuses.filter(
          (item) => item.value
        );
        this.statusesList.map((item) => {
          this.orderStatusList.data.map((itemGroup) => {
            if (item.id === itemGroup.id) {
              item.orders_count = itemGroup.orders_count;
              item.color = itemGroup.color;
            }
          });
        });
      }
    },
    ordersNewList(e) {
      let actualDepartment = null;
      if (this.searchQuery && this.searchQuery.title === "department_id") {
        actualDepartment = this.departments.data.filter(
          (departmentItem) => departmentItem.id === this.searchQuery.id
        )[0];
      }
      this.statusIdNewList = [];
      e.map((item) => {
        if (item.status && this.userGroupOrderStatus(item)) {
          const dataList = item.orders.data;
          if (!actualDepartment) {
            this.statusIdNewList.push({
              sort: item.sort,
              id: item.id,
              title: item.title,
              color: item.color,
              address: item.address,
              list: [...dataList],
              total: item.orders.total,
              per_page: item.orders.per_page,
              price: item.sumOrders,
              combining_orders_status: item.combining_orders_status,
              highlight_sufficient_amount_goods:
                item.highlight_sufficient_amount_goods,
            });
          } else {
            actualDepartment.show_order_statuses.map((itemDeparmentStatus) => {
              if (
                itemDeparmentStatus.id === item.id &&
                itemDeparmentStatus.value
              ) {
                this.statusIdNewList.push({
                  sort: item.sort,
                  id: item.id,
                  title: item.title,
                  color: item.color,
                  address: item.address,
                  list: [...dataList],
                  total: item.orders.total,
                  per_page: item.orders.per_page,
                  price: item.sumOrders,
                });
              }
            });
          }
        }
      });
      this.statusIdNewList = this.statusIdNewList.sort(
        (a, b) => b.sort - a.sort
      );
    },
    oneOrderList(e) {
      this.statusIdNewList.map((item) => {
        if (e[0].id === item.id) {
          item.list = e[0].orders.data;
        }
      });
    },
  },
  created() {
    this.getUsersGroup();
    this.getDeliveryAreas();
    this.toggleLoadingStatus(true);
    this.getOrderStatuses().then(() => {
      this.getDepartments().then(() => {
        this.getPaymentMethods().then(() => {
          this.getUsers().then(() => {
            this.getShipmentMethods().then(() => {
              this.getOrdersList().then(() => {
                this.getSites().then(() => {
                  this.toggleLoadingStatus(false);
                  if (this.$route.params.id) {
                    this.showOrder(this.$route.params.id);
                    this.showPopup = true;
                  }
                });
              });
            });
          });
        });
      });
    });
    setTimeout(() => {
      this.showStatusesList = true;
    }, 1500);
  },
  methods: {
    userGroupOrderStatus(item) {
      const orderStatus = this.profile.group.accept_to_statuses.filter(
        (orderStatusItem) => orderStatusItem.id === item.id
      )[0];
      return Boolean(orderStatus.value);
    },
    openAddPopup() {
      this.showPopup = true;
    },
    timerReady() {
      let payload = {
        page: this.page,
        perpage: this.perPage,
      };
      if (this.activeStatus !== 0) {
        payload.order_status_id = this.activeStatus;
      }
      if (this.filterData.query && this.filterData.query.length) {
        payload[this.filterData.type] = this.filterData.query;
      }
    },
    removeFilterItem(index) {
      this.filterList.splice(index, 1);
      this.createPayloadForSearch();
    },
    setUniteOrders() {
      if (this.validationUniteOrders()) {
        this.showConfirmUnitePopup = true;
      }
    },
    confirmUnite() {
      let ordersList = _.clone(this.selectedItem);
      let idChangeList = [];
      const firstOrder = ordersList.pop();
      ordersList.map((itemOrder) => {
        itemOrder.productList.map((itemProduct) => {
          if (
            !firstOrder.productList.some(
              (item) => item.product_id === itemProduct.product_id
            )
          ) {
            firstOrder.productList.push(itemProduct);
          } else {
            firstOrder.productList.map((item) => {
              if (item.product_id === itemProduct.product_id) {
                item.quantity += itemProduct.quantity;
              }
            });
          }
        });
        idChangeList.push(itemOrder.id);
      });
      let payloadChangeList = {
        orders_id: idChangeList,
        order_status_id: 31,
      };
      this.changeOrdersStatus(payloadChangeList);
      let payload = null;
      this.showOrder(firstOrder.id).then(() => {
        payload = _.clone(this.order);
        payload.products = firstOrder.productList;
        this.changeOrder({
          id: payload.id,
          data: payload,
        });
      });
    },
    validationUniteOrders() {
      let validate = true;
      let phoneNumber = this.selectedItem[0].order_phone;
      this.selectedItem.map((item) => {
        if (phoneNumber !== item.order_phone) {
          this.$toasted.error("Не співпадають номера телефонів.", {
            duration: 3000,
          });
          validate = false;
        } else if (item.product_status_id !== 1) {
          this.$toasted.error('Статус замовлень повинен бути "новий"', {
            duration: 3000,
          });
          validate = false;
        }
      });
      return validate;
    },
    openRememberPopup(e) {
      this.orderData = e.form;
      this.showPopupRemember = e.status;
    },
    addProduct(e) {
      this.newProduct = e;
    },
    sendOrder(item) {
      this.addOrder(item).then((res) => {
        if (res.status) {
          this.items = [];
          this.$toasted.success("Статус успішно доданий", {
            duration: 3000,
          });
          this.showPopup = false;
          this.getOrdersList();
        } else {
          this.showErrors(res.data.errors);
        }
      });
    },
    changeOrder(item) {
      this.editOrder(item).then((res) => {
        if (res.status) {
          this.items = [];
          this.$toasted.success("Статус успішно змінений", {
            duration: 3000,
          });
          this.showPopup = false;
          this.getOrdersList();
        } else {
          this.showErrors(res.data.errors);
        }
      });
    },
    showErrors(e) {
      let key;
      for (key in e) {
        this.$toasted.error(e[key], {
          duration: 3000,
        });
      }
    },
    openNpTtn(data) {
      this.orderData = data.form;
      this.showPopupTtn = data.status;
    },
    openInfoTtn() {
      this.showPopupInfoTtn = true;
    },
    toggleStatus(item) {
      const payload = {
        id: item.id,
        data: {
          title: item.title,
          status: Boolean(item.status),
        },
      };
      this.editOrder(payload);
    },
    selectItem(item) {
      if (item.length) {
        this.selectedItem = item;
      } else {
        this.selectedItem = null;
      }
    },
    editClickItem(item) {
      if (item) {
        this.showOrder(item.id);
        this.showPopup = true;
      }
    },
    removeItem(id) {
      this.removeOrder(id).then((res) => {
        if (res.status) {
          this.items = [];
          this.$toasted.success("Статус успешно удален", {
            duration: 3000,
          });
          this.showPopup = false;
        }
      });
    },
    showProduct(id) {
      this.getShowProduct(id);
      this.showProductPopup = true;
    },
    showMassage(result) {
      this.orderData = result.form;
      this.orderDataOrder_id = result.id;
      this.showMassagePopup = result.status;
    },
    toggleCallPopup(result) {
      this.showCallPopup = result.status;
      this.callOrderId = result.order_id;
      this.callForm = result.form;
    },
    beginScrollToRight() {
      this.scrollRight();
      this.changePosition = true;
    },
    beginScrollToLeft() {
      this.scrollLeft();
      this.changePosition = true;
    },
    scrollTo(element, scroll, navigate) {
      element.scrollLeft += scroll;
      if (navigate === "right") {
        this.scrollRight();
      } else {
        this.scrollLeft();
      }
    },
    scrollRight: _.debounce(function() {
      if (this.changePosition) {
        const content = this.$refs.drag.$refs.content;
        this.scrollTo(content, 5, "right");
      }
    }, 5),
    scrollLeft: _.debounce(function() {
      if (this.changePosition) {
        const content = this.$refs.drag.$refs.content;
        this.scrollTo(content, -5, "left");
      }
    }, 5),
    ...mapActions({
      showOrder: "orders/showOrder",
      editOrder: "orders/editOrder",
      addOrder: "orders/addOrder",
      removeOrder: "orders/removeOrder",
      getShipmentMethods: "orders/getShipmentMethods",
      getOrderStatuses: "orders/getOrderStatuses",
      getDepartments: "catalog/getDepartments",
      getPaymentMethods: "orders/getPaymentMethods",
      getShowProduct: "catalog/showProduct",
      changeOrdersStatus: "orders/changeOrdersStatus",
      getUsers: "contacts/getUsers",
      getUsersGroup: "contacts/getUsersGroup",
      getTtnNotification: "orders/getTtnNotification",
      getOrdersList: "orders/getOrdersList",
      getDeliveryAreas: "orders/getDeliveryAreas",
      getSites: "catalog/getSites",
    }),
    ...mapMutations({
      toggleLoadingStatus: "config/toggleLoadingStatus",
      toggleMenuHide: "config/toggleMenuHide",
    }),
  },
  mounted() {
    const width = window.innerWidth;
    if (width <= 1280) {
      this.toggleMenuHide(true);
    }
  },
};
