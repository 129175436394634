import {mapActions, mapGetters} from "vuex";
import paginationMixin from '@/mixins/pagination/index.js'
import showPage from "../../../mixins/showPage";

export default {
    components: {
        Header: () => import('@/components/header/index.vue'),
        leftBar: () => import('@/components/left-bar/index.vue'),
        TableOptions: () => import('@/components/table/options/index.vue'),
        Table: () => import('@/components/table/list/index.vue'),
        pagination: ()=> import('@/components/pagination/index.vue'),
        ForwardingPopUp: ()=> import('@/components/popups/call-list/forwarding.vue')
    },
    mixins:[paginationMixin, showPage],
    data() {
        return {
            pageId:41,
            fields: [
                {
                    key: 'call_order_id',
                    label: 'order id',
                },
                {
                    key: 'call_fio',
                    label: 'ФІО',
                },
                {
                    key: 'internal_number',
                    label: this.$t('tables.internal_number'),
                },
                {
                    key: 'external_number',
                    label: this.$t('tables.external_number'),
                },
                {
                    key: 'call_created_at',
                    label: this.$t('tables.call_created_at'),
                },
                {
                    key: 'callType',
                    label: this.$t('tables.call_type'),
                },
                {
                    key: 'billsec',
                    label: this.$t('tables.billsec'),
                },
                {
                    key: 'status_call',
                    label: 'Статус',
                },
                {
                    key: 'forwarding',
                    label: 'Переадресація',
                },
                {
                    key: 'binotel_id',
                    label: this.$t('tables.binotel_id'),
                },
            ],
            items: [],
            popupOpen: false,
            choiceForwarding: null,
        }

    },
    computed: {
        ...mapGetters({
            menuHide: 'config/menuHide',
            callList: 'templates/callList',
            callLinkData: 'templates/callLinkData',
        })
    },
    watch: {
        callList(e) {
            this.items = [];
            e.data.map(item => {
                let callType = 'Нет данных'
                switch (item.call_type){
                    case 0:
                        callType = 'Входячий'
                        break;
                    case 1:
                        callType = 'Виходячий'
                        break;
                }
                this.items.push({
                    call_id: item.id,
                    call_order_id: item.order_id ? item.order_id : 'Отсутствует',
                    call_fio: item.customer ? item.customer.name : '-',
                    internal_number: item.internal_number,
                    external_number: item.external_number,
                    billsec: item.billsec ? item.billsec : 'Нет данных',
                    status_call: item.status === 'BUSY' ? 'Зайнято' : item.status === 'ANSWER' ? 'Відповідь' : item.status === 'CANCEL' ? "Відміна" : item.status === 'CHANUNAVAIL' ? "Невірний номер" : item.status,
                    binotel_id: {id:item.binotel_id, record: this.callLinkData ? this.callLinkData.result.models : null},
                    call_created_at: this.$moment(item.created_at).format('HH:mm DD.MM.YYYY'),
                    forwarding: item.history_data,
                    callType: callType,
                })
            })

            this.res = e;
        },
        page(e) {
            this.getCallList({page: e, perpage: this.perPage});
        },
        per_page(e) {
            this.getCallList({page: this.page, perpage: e});
        },
        callLinkData(e){
            this.items.map(item => {
                if(item.binotel_id.id === e.binotel_id){
                    item.binotel_id.record = e.result.models
                }
            })
        }
    },
    created() {
        this.getCallList({page: this.page, per_page: this.per_page});
    },
    methods: {
        showForwarding(item){
            this.popupOpen = true
            this.choiceForwarding = item
        },
        toggleForwardingPopup(status){
          this.popupOpen = status;
        },
        getBinotelCallLink(binotel_id){
            this.getCallLink(binotel_id).then(res=>{
                if(res.status){
                    this.$toasted.success(res.data.message,{
                        duration: 3000
                    })
                }else{
                    this.$toasted.error(res.data.message,{
                        duration: 3000
                    })
                }
            });
        },
        getSearchInfo(data){
            if(data.type === 'call_order_id'){
                data.type = 'order_id'
            }
            if(data.query){
                this.getCallList({[data.type]: data.query});
            }else{
                this.getCallList({page: this.page, per_page: this.per_page});
            }
        },
        mailingReload(){
            this.getCallList();
        },
        ...mapActions({
            getCallList: 'templates/getCallList',
            getCallLink: 'templates/getCallLink',
        })
    }

}
