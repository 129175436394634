import {mapActions, mapGetters, mapMutations} from 'vuex';

const _ = require('lodash');

export default {
    data(){
      return{
          newMenuList: null,
          themeId: JSON.parse(localStorage.getItem('themeId')) ? localStorage.getItem('themeId') : 0,
          colOrders: null,
      }
    },
    computed: {
        ...mapGetters({
            menuList:'config/menuList',
            menuHide:'config/menuHide',
            openMenuItem:'config/openMenuItem',
            colNewOrders:'orders/colNewOrders',
            profile:'profile/profile',
            usersGroup: 'contacts/usersGroup',
            ordersNewList: 'orders/ordersNewList',

        })
    },
    watch:{
        colNewOrders(e){
            this.colOrders = e.filter(item => item.user_id === this.profile.id)[0].count
        }
    },
    created() {
        this.getUsersGroup().then(res=>{
            if(!this.profile){
                this.getProfile().then(()=>{
                    this.filterMenuList(res.data)
                })
            }else{
                this.filterMenuList(res.data)
            }
        });
    },
    methods:{
        filterMenuList(e){
            let userGroup = e.filter(item=>item.id === this.profile.user_group_id)[0]
            userGroup = userGroup.accept_to_menu_items;
            this.newMenuList = _.clone(this.menuList);
            this.newMenuList.map(menuItem => {
                let colValueTrue = 0;
                if(menuItem.list){
                    menuItem.list.map(listItem=>{
                        userGroup.map(item=>{
                            if(listItem.name === item.item_name){
                                listItem.value = item.value
                            }
                            if(listItem.value){
                                colValueTrue++;
                            }
                        })
                    })
                    menuItem.value = colValueTrue;
                }
            })
            this.newMenuList[0].value = 1;
        },
        showMenuList(id){
            this.changeOpenMenuItem(id);
            this.toggleMenuHide(false);
        },
        toggleSideBar(){
            this.changeOpenMenuItem(0);
            this.toggleMenuHide(!this.menuHide);
        },
        ...mapActions({
            getUsersGroup:'contacts/getUsersGroup',
            getProfile:'profile/getProfile',
        }),
        ...mapMutations({
            toggleMenuHide:'config/toggleMenuHide',
            changeOpenMenuItem:'config/changeOpenMenuItem',
        })
    }
}
