import {mapGetters, mapActions, mapMutations} from 'vuex';
import NotificationsPopup from '@/components/popups/notification/index.vue'
import OptionsPopup from '@/components/popups/options/index.vue'
const _ = require('lodash');

export default {
    components: {
        NotificationsPopup,
        OptionsPopup
    },
    data(){
      return{
          useBrowse:'',
          useOs: '',
          toggleProfileModal:false,
          showNotificationModal: false,
          showOptionsPopup: false,
          remember:{
              notification_id: this.$moment().format("DD MM YYYY HH mm ss").split(' ').join(''),
              date: this.$moment(new Date()).format('YYYY-MM-DD'),
              time:this.$moment(new Date()).format('HH:mm:ss'),
              type:2,
              description:'',
              created_at:this.$moment(new Date()).format('DD.MM.YYYY HH:mm'),
              status:0,
          },
          createdNotification: false,
      }
    },
    computed: {
        ...mapGetters({
            users:'contacts/users',
            menuHide:'config/menuHide',
            profile:'profile/profile',
            notifications: 'notification/notifications',
            colNewOrders:'orders/colNewOrders'
        }),
    },
    created() {
        this.getUsers();
        this.getUserBrowser();
        this.getUserOs();
        this.getNotifications();
        this.getProfile();
    },
    updated () {
        this.notifications.map( item => {
            if(this.$moment(item.date_to).unix() < this.$moment().unix()) {
                const result = {...item, expired: true}
                this.editNotification(result)
                return result
            }
            return {...item}
        })
    },
    watch:{
        profile(){
            this.getUserMainCard()
        }
    },
    methods:{
        getUserMainCard(){
            const lastTime = localStorage.getItem('lastTime') ? localStorage.getItem('lastTime') : this.$moment(new Date());
            const currentItem = this.$moment(new Date());
            const userId = this.profile.id;
            const user = this.users.filter(item => item.id === userId)[0];
            if(user.cards && !this.createdNotification){
                const userCards = user.cards;
                const mainUserCard =  userCards.filter(item => item.main)[0];
                if(mainUserCard){
                    const cardLimit = mainUserCard.card_limit;
                    if(currentItem.diff(lastTime, 'minutes') > 10 || currentItem.diff(lastTime, 'minutes') === 0){
                    var begin = this.$moment().format("01.MM.YYYY");
                    var end = this.$moment().format(`${this.$moment().daysInMonth()}.MM.YYYY`)
                    this.getStatements({
                        card: mainUserCard.card,
                        account_id: mainUserCard.account_id,
                        password: mainUserCard.password,
                        to: begin,
                        from: end,
                        onlyTotal: 1,
                    }).then(res=>{
                      if(res.status){
                          if(Number(res.data.models.total) > Number(cardLimit)){
                              this.remember.description = `На вашій головній картці перевищений ліміт на ${(Number(res.data.models.total) - Number(cardLimit)).toFixed(2)} грн`;
                              this.createdNotification = true
                              this.createRemember();
                              localStorage.setItem('lastTime', currentItem);
                          }
                      }
                    })
                }
                }
            }
        },

        createRemember(){
            if( this.profile.options){
                let options = _.clone(this.profile.options);
                if(options.remember){
                    options.remember.push({
                        ...this.remember
                    })
                }else {
                    options['remember'] = [{...this.remember}]
                }
                const payload = {
                    user_id: this.profile.id,
                    options:{
                        remember:[
                            ...options.remember
                        ]
                    }
                }
                this.sendEditUser(payload);
            }else{
                const payload = {
                    user_id: this.profile.id,
                    options:{
                        remember:[
                            {...this.remember}
                        ]
                    }
                }
                this.sendEditUser(payload);
            }
        },
        sendEditUser(payload){
            this.editUser(payload).then(res=>{
                if(res.status){
                    this.getProfile().then(()=>{
                        this.$emit('closePopup')
                    })
                }
            })
        },
        showOptions() {
            this.showOptionsPopup = true
        },
        closePopup() {
            this.showNotificationModal = false
        },
        getUserBrowser(){
            let sUsrAg = navigator.userAgent;
            if (sUsrAg.indexOf("Firefox") > -1) {
                this.useBrowse = "Mozilla Firefox";
                //"Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
            } else if (sUsrAg.indexOf("Opera") > -1) {
                this.useBrowse = "Opera";
            } else if (sUsrAg.indexOf("Trident") > -1) {
                this.useBrowse = "Microsoft Internet Explorer";
                //"Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
            } else if (sUsrAg.indexOf("Edge") > -1) {
                this.useBrowse = "Microsoft Edge";
                //"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
            } else if (sUsrAg.indexOf("Chrome") > -1) {
                this.useBrowse = "Google Chrome or Chromium";
                //"Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
            } else if (sUsrAg.indexOf("Safari") > -1) {
                this.useBrowse = "Apple Safari";
                //"Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
            } else {
                this.useBrowse = "unknown";
            }
        },
        getUserOs(){
            let OSName = "Unknown";
            if (window.navigator.userAgent.indexOf("Windows NT 10.0")!= -1) OSName="Windows 10";
            if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) OSName="Windows 8";
            if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) OSName="Windows 7";
            if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) OSName="Windows Vista";
            if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) OSName="Windows XP";
            if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) OSName="Windows 2000";
            if (window.navigator.userAgent.indexOf("Mac")            != -1) OSName="Mac/iOS";
            if (window.navigator.userAgent.indexOf("X11")            != -1) OSName="UNIX";
            if (window.navigator.userAgent.indexOf("Linux")          != -1) OSName="Linux";
            this.useOs = OSName;
        },
        actualLengthNotifications(remember){
            let count = 0;
            remember.map(item=>{
                if(item.status === 0){
                    count++;
                }
            })
            return count;
        },
        ...mapActions({
            getUsers:'contacts/getUsers',
            editUser:'contacts/editUser',
            getProfile:'profile/getProfile',
            getStatements:'rate/getStatements',
            getNotifications: 'notification/getNotifications',
            editNotification: 'notification/editNotification',
        }),
        ...mapMutations({
            changeToggleExitConfirm: 'config/changeToggleExitConfirm'
        })
    }
}
