import {mapActions, mapGetters, mapMutations} from 'vuex';
import paginationMixin from '@/mixins/pagination/index.js'
import showPage from "../../mixins/showPage";

export default {
    components:{
        Header: () => import('@/components/header/index.vue'),
        leftBar: () => import('@/components/left-bar/index.vue'),
        Table: () => import('@/components/table/list/index.vue'),
        pagination: () => import('@/components/pagination/index.vue'),
        warehousePopup: () => import('@/components/popups/warehouse/index.vue'),
        TableOptions: () => import('@/components/table/options/index.vue')
    },
    mixins: [paginationMixin, showPage],
    data(){
        return{
            pageId:23,
            fields:[
                {
                    key: 'id',
                    label: 'ID'
                },
                {
                    title: this.$t('tables.title')
                }
            ],
            items:[],
            showWarehousePopup: false,
            selectedItem: null,
        }
    },
    computed: {
        ...mapGetters({
            menuHide:'config/menuHide',
            warehouses:'warehouse/warehouses',
            warehouse:'warehouse/warehouse',
        })
    },
    watch:{
        warehouses(e){
            this.items = []
            e.data.map(item=>{
                this.items.push({'title': item.title, id:item.id});
            })
            this.res = e
        },
        page(e) {
            this.getWarehouses({page: e, per_page: this.per_page});
        },
        per_page(e) {
            this.getWarehouses({page: this.page, per_page: e});
        }
    },
    created() {
        this.toggleLoadingStatus(true);
        this.getWarehouses({page: 1, per_page: this.per_page}).then(()=>{
            this.toggleLoadingStatus(false);
        });
    },
    methods:{
        getAllItemsInTable(){
            this.selectedItem = this.items;
            this.$refs.tableComponent.getAllItemsInTable();
        },
        changePopup(item){
            this.showWarehouse(item.id)
            this.showWarehousePopup = true;
        },
        editClickWarehouse(item){
            this.showWarehouse(item.id)
            this.showWarehousePopup = true;
        },
        selectItem(item){
            if(item.length){
                this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        sendWarehouse(form){
            this.addWarehouse(form).then(res=>{
                if(res.status){
                    this.getWarehouses();
                    this.$toasted.success('Склад успешно добавлен',{
                        duration : 3000
                    });
                }
                this.showWarehousePopup = false;
            })
        },
        changeWarehouse(form){
            this.editWarehouse(form).then(res=>{
                if(res.status){
                    this.getWarehouses();
                    this.$toasted.success('Склад успешно обновлен',{
                        duration : 3000
                    });
                    this.showWarehousePopup = false;
                }
            })
        },
        removeItem(id){
          this.removeWarehouse(id).then(res=>{
              if(res.status){
                  this.getWarehouses();
                  this.$toasted.success('Склад успешно удален',{
                      duration : 3000
                  });
                  this.showWarehousePopup = false;
              }
          })
        },
        ...mapActions({
            getWarehouses:'warehouse/getWarehouses',
            addWarehouse:'warehouse/addWarehouse',
            showWarehouse:'warehouse/showWarehouse',
            editWarehouse:'warehouse/editWarehouse',
            removeWarehouse:'warehouse/removeWarehouse',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        })
    }
}
