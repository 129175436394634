import {mapGetters, mapActions} from "vuex";


export default {
    components:{
      interface: ()=> import('./interface/index.vue'),
      sounds: ()=> import('./sounds/index.vue'),
    },
    data() {
        return {
           languages: [
               {
                   code: 'uk',
                   title: 'Ukrainian'
               },
               {
                   code: 'ru',
                   title: 'Russian'
               },
               {
                   code: 'en',
                   title: 'English'
               }
           ],
            language: null,
            langs: ['uk', 'ru'],
            themes:[
                {
                    id:0,
                    label: this.$t('options.dark-theme'),
                },
                {
                    id:1,
                    label: this.$t('options.white-theme'),
                },
            ],
            activeTheme: 0,
            imgBckStatus: false,
            image:null,
            file:null,
        }
    },
    computed:{
        ...mapGetters({
            profile:'profile/profile'
        })
    },
    created() {
        this.activeTheme = this.profile.options.themeId ? this.profile.options.themeId : false;
        this.imgBckStatus = this.profile.options.imgBckStatus ? this.profile.options.imgBckStatus : false;
        this.image = this.profile.options.image ? this.profile.options.image : false;
    },
    methods: {
        getFile(file){
            this.file = file;
        },
        choiceBckImg(status){
            this.imgBckStatus = status;
        },
        changeThemeId(id){
            this.activeTheme = id;
        },
        closePopup() {
            this.$emit('closePopup')
        },
        saveOptions() {
            let options = this.profile.options;
            if(!options){ options = {} }
            options.themeId = this.activeTheme;
            options.imgBckStatus = this.imgBckStatus;
            localStorage.setItem('themeId', this.activeTheme);
            const payload = {
                user_id: this.profile.id,
                description: this.profile.description,
                email: this.profile.email,
                login: this.profile.login,
                name: this.profile.name,
                user_group_id: this.profile.user_group_id,
                options,
            };
            if(this.file){
                this.storeProductImage(this.file).then( res => {
                    payload.options.image = res.image;
                    this.sendEditUser(payload);
                })
            }else{
                this.sendEditUser(payload);
            }
        },
        sendEditUser(payload){
            this.editUser(payload).then(res=>{
                if(res.status){
                    this.$toasted.success('Изменения успешно сохранены',{
                        duration: 3000
                    })
                    this.closePopup();
                }else{
                    this.$toasted.error('что-то пошло не так',{
                        duration: 3000
                    })
                }
            });
        },
        ...mapActions({
            editUser:'contacts/editUser',
            storeProductImage: 'catalog/storeProductImage',
        })
    }
}
