import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    components:{
        Header: ()=> import('@/components/header/index.vue'),
        leftBar: ()=> import('@/components/left-bar/index.vue'),
        TableOptions: ()=> import('@/components/table/options/index.vue'),
        Table: ()=> import('@/components/table/list/index.vue'),
    },
    data(){
       return{
           fields:[
               {
                   key:'id',
                   label:'№',
               },
               {
                   key:'register_number',
                   label:'Номер реєстру',
               },
               {
                   key:'register_count',
                   label:'Кіл-ть ЕН',
               },
               {
                   key:'register_created',
                   label:'Дата створення',
               },
               {
                   key:'register_dop',
                   label:'Додатково',
               },
               {
                   key:'register_ref',
                   label:'Ref',
               },
               {
                   key:'register_printed',
                   label:'Стан',
               },
               {
                   key:'register_print',
                   label:'Друк',
               },
           ],
           items:[],
       }
    },
    computed:{
      ...mapGetters({
          registryList:'orders/registryList',
          options:'config/options',
      })
    },
    watch:{
        registryList(e){
            this.items = [];
            let i = 0;
            e.models.map(item=>{
                i++
                this.items.push({
                    id:i,
                    register_number: item.Number,
                    register_count: item.Count,
                    register_created: item.DateTime,
                    register_dop: item.Description,
                    register_ref: item.Ref,
                    register_printed: item.Printed,
                    register_print: {ref: item.Ref, key: this.options.models.np_api_key}
                })
            });
        }
    },
    created() {
        this.toggleLoadingStatus(true);
        this.getOptions().then(()=>{
            this.getRegistrylist().then(()=>{
                this.toggleLoadingStatus(false);
            })
        });
    },
    methods:{
        editClickItem(){
        },
        removeItem(){
        },
        ...mapActions({
            getRegistrylist:'orders/getRegistrylist',
            getOptions:'config/getOptions',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        }),
    }
}