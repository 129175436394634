export default {
    name: 'Pagination',
    props: ['res'],
    data() {
        return {
            per_page: 50,
            page: 1,
            firstVisiblePage: 1,
            lastVisiblePage: 7,
        }
    },
    created() {
        this.firstVisiblePage = 1
        this.validLastVisiblePage()
    },
    watch:{
        res(){
            this.validLastVisiblePage()
        }
    },
    methods: {
        range(start, end) {
            const arr = []
            for(let i = start; i <= end; i++) {
                arr.push(i)
            }
            return arr
        },
        validLastVisiblePage(){
            if(this.firstVisiblePage + 7 < this.res.last_page){
                this.lastVisiblePage = this.firstVisiblePage + 7;
            }else{
                this.lastVisiblePage = this.res.last_page;
            }
        },
        changePerPageCount() {
            this.$emit('changePerPage', this.per_page)
        },
        changePage(i) {
            this.$emit('changePage', i)
            if(i > 1 && this.res.last_page - i > 1){
                this.firstVisiblePage = i - 1;
            }
            this.validLastVisiblePage();
        },
        lastPage() {
            this.$emit('toLastPage', this.res.last_page);
            if(this.res.last_page > 7){
             this.firstVisiblePage = this.res.last_page - 6;
            }
            this.validLastVisiblePage();
        },
        firstPage() {
            this.$emit('toFirstPage', 1);
            this.firstVisiblePage = 1;
            this.validLastVisiblePage();
        },
        nextPage() {
            this.$emit('nextPage', this.page+1)
        },
        prevPage() {
            this.$emit('prevPage', this.page-1)
        }
    }
}
