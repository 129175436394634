import {mapActions, mapGetters, mapMutations} from 'vuex';
import paginationMixin from '@/mixins/pagination/index.js'
import filterSearch from '@/mixins/filter-search/index.js'
import * as moment from "moment/moment";
import showPage from "../../../mixins/showPage";

export default {
    components:{
        Header: ()=> import('@/components/header/index.vue'),
        leftBar: ()=> import('@/components/left-bar/index.vue'),
        Table: ()=> import('@/components/table/list/index.vue'),
        pagination: ()=> import('@/components/pagination/index.vue'),
        TableOptions: ()=> import('@/components/table/options/index.vue'),
        popup: ()=> import('@/components/popups/contacts/customers/index.vue'),
        carousel: ()=> import('vue-owl-carousel'),
    },
    mixins: [paginationMixin, filterSearch, showPage],
    data(){
        return{
            pageId:5,
            fields:[
                {
                    key: 'customer_id',
                    label: 'id'
                },
                {
                    key: 'name',
                    label: this.$t('tables.client')
                },
                {
                    key: 'mobile',
                    label: this.$t('tables.phone')
                },
                {
                    key: 'wholesale',
                    label: this.$t('global.drop-shipping')
                },
                {
                    key: 'email',
                    label: 'email'
                },
                {
                    key: 'comment',
                    label: this.$t('tables.comment')
                },
                {
                    key: 'added',
                    label: this.$t('tables.added')
                },
                {
                    key: 'web-site',
                    label: this.$t('tables.site')
                },
                {
                    key: 'ip',
                    label: this.$t('tables.ip-address')
                }
            ],
            items:[],
            selectedItem: null,
            showPopup: false,
            activeStatus: 0,
        }
    },
    computed: {
        ...mapGetters({
            menuHide:'config/menuHide',
            customers:'contacts/customers',
            customerGroups:'contacts/customerGroups',
        })
    },
    watch:{
        customers(e){
            this.items = []
            e.data.map(item=>{
                this.items.push({
                    customer_id:item.id,
                    'name': item.name,
                    'mobile': item.phone,
                    'wholesale': item.wholesale ? "+" : "-",
                    'email': item.email,
                    'comment': item.comment,
                    'added':  moment(item.created_at).format('YYYY-MM-DD HH:mm'),
                    'web-site': item.site,
                    'ip': item.ip,
                });
            })
            this.res = e
        },
        page() {
            this.searchPayload.page = this.page;
            this.searchPayload.per_page = this.per_page;
            this.getCustomers(this.searchPayload);
        },
        per_page(e) {
            this.searchPayload.per_page = e;
            this.getCustomers(this.searchPayload);
        }
    },
    created() {
        this.toggleLoadingStatus(true);
        this.getCustomerGroups().then(()=>{
            this.getCustomers(this.searchPayload).then(()=>{
                this.toggleLoadingStatus(false);
            });
        })
    },
    methods:{
        removeFilterItem(index){
            this.filterList.splice(index, 1);
            this.createPayloadForSearch();
        },
        getPayloadForSearch(){
            if(this.activeStatus !== 0){
                this.searchPayload['customer_group_id'] = this.activeStatus;
                    this.getCustomers(this.searchPayload);

            }else{
                this.getCustomers(this.searchPayload);

            }
        },
        getAllItemsInTable(){
            this.selectedItem = this.items;
            this.$refs.tableComponent.getAllItemsInTable();
        },
        changePopup(item){
            this.showCustomer(item.customer_id)
            this.showPopup = true;
        },
        editClickWarehouse(item){
            this.showCustomer(item.id)
            this.showPopup = true;
        },
        selectItem(item){
            if(item.length){
                this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        sendCustomer(form){
            this.addCustomer(form).then(res=>{
                if(res.status){
                    this.getCustomers(this.searchPayload)
                    this.$toasted.success('Клиент успешно добавлен',{
                        duration : 3000
                    });
                }
                this.showPopup = false;
            })
        },
        changeCustomer(form){
            this.editCustomer(form).then(res=>{
                if(res.status){
                    this.getCustomers(this.searchPayload)
                    this.$toasted.success('Клиент успешно изменен',{
                        duration : 3000
                    });
                    this.showPopup = false;
                }
            })
        },
        removeItem(id){
          this.removeCustomer(id).then(res=>{
              if(res.status){
                  this.getCustomers(this.searchPayload)
                  this.$toasted.success('Клиент успешно удален',{
                      duration : 3000
                  });
                  this.showPopup = false;
              }
          })
        },
        getClientFromStatus(id){
            this.activeStatus = id;
            this.searchPayload['customer_group_id'] = this.activeStatus
            this.getCustomers(this.searchPayload)
        },
        ...mapActions({
            getCustomers:'contacts/getCustomers',
            showCustomer:'contacts/showCustomer',
            editCustomer:'contacts/editCustomer',
            addCustomer:'contacts/addCustomer',
            removeCustomer:'contacts/removeCustomer',
            getCustomerGroups:'contacts/getCustomerGroups',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        })
    }
}
