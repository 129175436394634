<template>
  <div class="logs">
    <div class="logs__title">
      Filename: {{logList.data.filename}}
    </div>
    <a class="link-back" href="/">← back to crm</a>
    <div class="logs__dates">
      <p>available_log_dates</p>
      <div class="logs__dates-list">
        <div class="logs__dates-item" v-for="(item, index) in logList.data.available_log_dates" :key="index">
          {{item}}
        </div>
      </div>
    </div>
    <div class="logs__date">
        date : {{logList.data.date}}
    </div>
    <div class="logs__list">
      <div class="logs__list-title">
        Logs
      </div>
      <div class="logs__item" v-for="(item, index) in logList.data.logs" :key="index">
        <div class="logs__item-label">
          <p>
            timestamp
          </p>
          <span>
            {{item.timestamp}}
          </span>
        </div>
        <div class="logs__item-label">
          <p>
            env
          </p>
          <span>
            {{item.env}}
          </span>
        </div>
        <div class="logs__item-label">
          <p>
            type
          </p>
          <span>
            {{item.type}}
          </span>
        </div>
        <div class="logs__item-label">
          <p>
            message
          </p>
          <span>
            {{item.message}}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  computed:{
    ...mapGetters({
      logList:'config/logList'
    })
  },
  created() {
    this.getLogs();
  },
  methods:{
    ...mapActions({
      getLogs:'config/getLogs'
    })
  }
}
</script>
<style lang="scss" scoped>
.logs{
  background-color: #f3f3f3;
  padding: 15px;
  &__title{
    font-size: 18px;
    margin-bottom: 15px;
  }
  &__dates{
    margin-bottom: 10px;
    p{
      font-size: 16px;
      margin-bottom: 5px;
    }
  }
  &__dates-list{
    display: flex;
  }
  &__dates-item{
    margin-right: 10px;
    font-size: 12px;
    &:after{
      content: ',';
    }
  }
  &__date{
    margin-bottom: 10px;
  }
  &__list{
    padding: 0px;
    display: flex;
    flex-direction: column;
  }
  &__list-title{
    font-size: 18px;
    margin-bottom: 20px;
  }
  &__item{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: #cccccc;
  }
  &__item-label{
    display: flex;
    margin: 2px 10px;
    min-width: 25%;
    align-items: center;
    p{
      margin-right: 10px;
      margin-bottom: 0;
      font-size: 14px;
      &:after{
        content: ':';
      }
    }
    span{
      font-size: 12px;
    }
  }
  .link-back{
    font-size: 14px;
    margin-bottom: 15px;
    display: block;
    color: rgb(126, 116, 116)
  }

}
</style>
