import axios from "axios";
import qs from 'qs';

axios.defaults.headers = {
    'Authorization':'Bearer ' + localStorage.getItem('token'),
    'Accept':'application/json',
}

const state = {
    showProductPopup: false,


    //product categories
    productCategories: null,
    productCategoryList: null,
    productCategory: null,
    //price categories
    priceCategories: null,
    priceCategory: null,
    //manufacturers
    manufacturers: null,
    manufacturer: null,
    //department
    departments: null,
    department: null,
    //products
    products: null,
    product: null,
    //materials
    materials: null,
    material: null,
    //models
    models: null,
    model: null,
    //sites
    sites: null,
    site: null,
    //leftLovers
    leftLovers: null
};

const getters = {
    showProductPopup: state => state.showProductPopup,



    //product categories
    productCategoryList: state => state.productCategoryList,
    productCategories: state => state.productCategories,
    productCategory: state => state.productCategory,
    //price categories
    priceCategories: state => state.priceCategories,
    priceCategory: state => state.priceCategory,
    //manufacturers
    manufacturers: state => state.manufacturers,
    manufacturer: state => state.manufacturer,
    //department
    departments: state => state.departments,
    department: state => state.department,
    //materials
    materials: state => state.materials,
    material: state => state.material,
    //models
    models: state => state.models,
    model: state => state.model,
    //products
    products: state => state.products,
    product: state => state.product,
    //sites
    sites: state => state.sites,
    site: state => state.site,
    //leftLovers
    leftLovers: state => state.leftLovers
};
const actions = {

    //begin productCategory

    getProductCategories: async ({commit}, options) =>{
        try{
            const result = await axios.get('/categories',{
                params: options,
                paramsSerializer: (params) => {
                    return qs.stringify(params);
                }
            });
            commit('changeProductCategories', result.data.models);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    showProductCategory: async ({commit}, id) =>{
        try{
            const result = await axios.get('/categories/'+id);
            commit('changeProductCategory', result.data.model);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    editProductCategory: async ({commit}, payload) =>{
        try{
            const result = await axios.put('/categories/'+payload.id, payload.data);
            commit('changes', result.data.models);
            return {
                status: true,
                data: result.data
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    addProductCategory: async ({commit}, payload) =>{
        try{
            const result = await axios.post('/categories/', payload);
            commit('changes', result.data);
            return {
                status: true,
                data: result
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    removeProductCategory: async ({commit}, id) =>{
        try{
            const result = await axios.post('/categories/'+id,{
                "_method" : "DELETE"
            });
            commit('changes', result.data);
            return {
                status: true,
                data: result
            }
        }catch (e){
            if(e.response.status === 302){
                return {
                    status: true,
                    data: e.data
                }
            }else{
                console.log(e);
            }
        }
    },

    //begin priceCategory

    getPriceCategories: async ({commit}, options) =>{
        let url = '/price_categories'
        if(options) {
            url = `/price_categories?page=${options.page}&per_page=${options.per_page}`
        }
        try{
            const result = await axios.get(url);
            commit('changePriceCategories', result.data.models);
            // console.log(result.data.models)
        }catch (e){
            console.log(e)
            throw e
        }
    },
    showPriceCategory: async ({commit}, id) =>{
        try{
            const result = await axios.get('/price_categories/'+id);
            commit('changePriceCategory', result.data.model);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    editPriceCategory: async ({commit}, payload) =>{
        try{
            const result = await axios.put('/price_categories/'+payload.id, payload.data);
            commit('changePriceCategory', result.data.models);
            return {
                status: true,
                data: result.data
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    addPriceCategory: async ({commit}, payload) =>{
        try{
            const result = await axios.post('/price_categories/', payload);
            commit('changePriceCategory', result.data.models);
            return {
                status: true,
                data: result.data
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    removePriceCategory: async ({commit}, id) =>{
        try{
            const result = await axios.post('/price_categories/'+id,{
                "_method" : "DELETE"
            });
            commit('changes', result.data.models);
        }catch (e){
            if(e.response.status === 302){
                return {
                    status: true,
                    data: e.data
                }
            }else{
                console.log(e);
            }
        }
    },

    //begin department

    getDepartments: async ({commit}, options) =>{
        try{
            const result = await axios.get('/departments',{
                params: options,
                paramsSerializer: (params) =>{
                    return qs.stringify(params);
                }
            });
            // console.log(result)
            commit('changeDepartments', result.data.models);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    showDepartment: async ({commit},id) =>{
        try{
            const result = await axios.get('/departments/'+id);
            commit('changeDepartment', result.data.model);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    editDepartment: async ({commit},payload) =>{
        try{
            const result = await axios.put('/departments/'+payload.id, payload.data);
            commit('changeDepartment', result.data.models);
            return {
                status: true,
                data: result.data
            }
        }catch (e){
            console.log(e)
            throw e;
        }
    },
    addDepartment: async ({commit},payload) =>{
        try{
            const result = await axios.post('/departments',payload);
            commit('changeDepartment', result.data.models);
            return {
                status: true,
                data: result.data
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    removeDepartment: async ({commit},id) =>{
        try{
            const result = await axios.post('/departments/'+id,{
                "_method" : "DELETE"
            });
            commit('changeDepartment', result.data.models);
        }catch (e){
            if(e.response.status === 302){
                return {
                    status: true,
                    data: e.data
                }
            }else{
                console.log(e);
            }
        }
    },

    //begin materials

    getMaterials: async ({commit}, options) =>{
        try{
            const result = await axios.get('/materials',{
                params: options,
                paramsSerializer: (params) =>{
                    return qs.stringify(params);
                }
            });
            commit('changeMaterials', result.data.models);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    showMaterial: async ({commit}, id) =>{
        try{
            const result = await axios.get('/materials/'+id);
            commit('changeMaterial', result.data.model);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    editMaterial: async ({commit},payload) =>{
        try{
            const result = await axios.put('/materials/'+ payload.id, payload.data);
            commit('changeMaterial', result.data.models);
            return {
                status: true,
                data: result.data
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    addMaterial: async ({commit},payload) =>{
        try{
            const result = await axios.post('/materials',payload);
            commit('changeMaterial', result.data.models);
            return {
                status: true,
                data: result.data
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    removeMaterial: async ({commit},id) =>{
        try{
            const result = await axios.post('/materials/'+id,{
                "_method" : "DELETE"
            });
            commit('change', result.data.models);
        }catch (e){
            if(e.response.status === 302){
                return {
                    status: true,
                    data: e.data
                }
            }else{
                console.log(e);
            }
        }
    },
    //begin models
    getModels: async ({commit}, options) =>{
        try{
            const result = await axios.get('/models',{
                params: options,
                paramsSerializer: (params) =>{
                    return qs.stringify(params);
                }
            });
            commit('changeModels', result.data.models);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    showModel: async ({commit},id) =>{
        try{
            const result = await axios.get('/models/'+id);
            commit('changeModel', result.data.model);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    editModel: async ({commit}, payload) =>{
        try{
            const result = await axios.put('/models/'+ payload.id, payload.data);
            commit('changeModel', result.data.model);
            return {
                status: true,
                data: result.data
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    addModel: async ({commit}, payload) =>{
        try{
            const result = await axios.post('/models', payload);
            commit('changeModels', result.data.models);
            return {
                status: true,
                data: result.data
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    removeModel: async ({commit},id) =>{
        try{
            const result = await axios.post('/models/'+id,{
                "_method" : "DELETE"
            });
            commit('changeModels', result.data.models);
        }catch (e){
            if(e.response.status === 302){
                return {
                    status: true,
                    data: e.data
                }
            }else{
                console.log(e);
            }
        }
    },
    //begin manufacturer
    getManufacturers: async ({commit}, options) =>{
        try{
            const result = await axios.get('/manufacturers',{
                params: options,
                paramsSerializer: (params) =>{
                    return qs.stringify(params);
                }
            });
            commit('changeManufacturers', result.data.models);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    showManufacturer: async ({commit}, id) =>{
        try{
            const result = await axios.get('/manufacturers/' + id);
            commit('changeManufacturer', result.data.model);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    editManufacturer: async ({commit}, payload) =>{
        try{
            const result = await axios.put('/manufacturers/'+payload.id, payload.data);
            commit('changeManufacturer', result.data.model);
            return {
                status: true,
                data: result.data
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    addManufacturer: async ({commit}, payload) =>{
        try{
            const result = await axios.post('/manufacturers', payload);
            commit('changeManufacturers', result.data.models);
            return {
                status: true,
                data: result.data
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    removeManufacturer: async ({commit}, id) =>{
        try{
            const result = await axios.post('/manufacturers/'+id,{
                "_method" : "DELETE"
            });
            commit('changeManufacturers', result.data.models);
        }catch (e){
            if(e.response.status === 302){
                return {
                    status: true,
                    data: e.data
                }
            }else{
                console.log(e);
            }
        }
    },
    //begin products
    getProducts: async ({commit}, options) =>{
        try{
            const result = await axios.get('/products',{
                params: options,
                paramsSerializer: (params) =>{
                    return qs.stringify(params);
                }
            });
            commit('changeProducts', result.data.models);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    getProductsForOrder: async ({commit}, options) =>{
        try{
            const result = await axios.get('/orders_get_list_products',{
                params: options,
                paramsSerializer: (params) =>{
                    return qs.stringify(params);
                }
            });
            commit('changeProducts', result.data.models);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    getProductsByCategory: async({commit}, id) => {
        try {
            const result = await axios.get('/categories/'+id+'/products')
            commit('changeProducts', result.data.models)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    showProduct: async ({commit},id) =>{
        try{
            const result = await axios.get('/products/'+id);
            commit('changeProduct', result.data.model);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    editProduct: async ({commit}, payload) =>{
        try{
            const result = await axios.put('/products/'+payload.id, payload.data);
            commit('changeProduct', result.data.model);
            return{
                status: true,
                data: result.data
            }
        }catch (e){
            return{
                status: false,
                data: e.response.data
            }
        }
    },
    addProduct: async ({commit}, payload) =>{
        try{
            const result = await axios.post('/products', payload);
            commit('changeProduct', result.data.model);
            return {
                status: true,
                data: result.data
            }

        }catch (e){
            return{
                status: false,
                data: e.response.data
            }
        }
    },
    storeProductImage: async(ctx, file) => {
        const data = new FormData()
        data.append('image', file)
      try {
        const result =  await axios.post('/store-image', data)
        return result.data;
      }catch (e) {
          console.log(e)
          throw e
      }
    },
    removeProduct: async ({commit},id) =>{
        try{
            const result = await axios.post('/products/'+id,{
                "_method" : "DELETE"
            });
            commit('change', result.data.model);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    // begin sites
    getSites: async({commit}, options) => {
        try {
            const result = await axios.get('/sites',{
                params: options,
                paramsSerializer: (params) =>{
                    return qs.stringify(params);
                }
            })
            commit('changeSites', result.data.models)
        }catch (e) {
            console.log(e)
            throw e
        }
    },
    showSite: async({commit}, id) => {
        try{
            const result = await axios.get('/sites/'+id);
            commit('changeSite', result.data.model);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    editSite: async ({commit}, payload) =>{
        console.log(payload)
        try{
            const result = await axios.put('/sites/'+payload.id, payload.data);
            commit('changeSite', result.data.model);
            return{
                status: true
            }
        }catch (e){
            return{
                status: false,
                data: e.response.data
            }
        }
    },
    addSite: async ({commit}, payload) =>{
        try{
            const result = await axios.post('/sites', payload);
            commit('changeSite', result.data.model);
            return{
                status: true
            }
        }catch (e){
            return{
                status: false,
                data: e.response.data
            }
        }
    },
    removeSite: async ({commit},id) =>{
        try{
            const result = await axios.post('/sites/'+id,{
                "_method" : "DELETE"
            });
            commit('changeSite', result.data.model);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    getLeftLovers: async ({commit}) =>{
        try{
            const result = await axios.get('/products/product_leftovers');
            commit('changeLeftLovers', result.data.models);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    changeLeftLovers: async (ctx, payload) =>{
        try{
            await axios.post('/products/update_products_hint',{ids : payload})
        }catch (e){
            console.log(e)
            throw e
        }
    },
    createRegistry: async (ctx, payload) => {
        try {
            const result = await axios.post('/np/create_registry', payload)
            return{
                status: true,
                data: result.data
            }
        } catch (e) {
            return {
                status: false,
                data: e.response.data
            }
        }
    }
}



const mutations = {
    changeShowProductPopup(state, status){
        state.showProductPopup = status;
    },



    // product categories
    changeProductCategories(state, status){
        // console.log(status)
        state.productCategories = status;
        state.productCategoryList = [];
        // let key;
        // for(key in status){
        //     state.productCategories.push(status[key]);
        // }
        state.productCategories.data.map(item=>{
            state.productCategoryList.push({'title':item.title, 'id':item.id});
        })
    },
    changeProductCategory(state, status){
        state.productCategory = status[0];
    },
    // price category
    changePriceCategories(state, status){
        state.priceCategories = status
    },
    changePriceCategory(state, status){
        state.priceCategory = status
    },
    // price Department
    changeDepartments(state, status){
        state.departments = status;
        // let key;
        // for(key in status){
        //     state.departments.push(status[key]);
        // }
    },
    changeDepartment(state, status){
        state.department = status;
    },
    changeMaterials(state, status){
        state.materials = status;
        // let key;
        // for(key in status){
        //     state.materials.push(status[key]);
        // }
    },
    changeMaterial(state, status){
        state.material = status;
    },
    changeModels(state, status){
        state.models = status;
    },
    changeModel(state, status){
        state.model = status;
    },
    changeManufacturers(state, status){
        state.manufacturers = status;
        // let key;
        // for(key in status){
        //     state.manufacturers.push(status[key]);
        // }
    },
    changeManufacturer(state, status){
        state.manufacturer = status;
    },
    changeProducts(state, status){
        state.products = status;
        // let key;
        // for(key in status){
        //     state.products.push(status[key]);
        // }
    },
    changeProduct(state, status){
        state.product = status;
    },
    changes(){},
    // sites
    changeSites(state, status) {
        state.sites = status
    },
    changeSite(state, status) {
        state.site = status
    },
    changeLeftLovers(state, status){
        state.leftLovers = status;
    }
};



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
