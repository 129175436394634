import { mapActions, mapGetters, mapMutations } from "vuex";
import icons from "../../../mixins/icons/index";
const _ = require("lodash");

export default {
  components: {
    confirmRemovePopup: () =>
      import("@/components/popups/remove-confirm/index.vue"),
    telephonePopup: () => import("@/components/popups/telephone/index.vue"),
    loader: () => import("@/components/loader/index.vue"),
  },
  mixins: [icons],
  props: [
    "selectedItem",
    "uniteOrders",
    "shipmentPrint",
    "timerStatus",
    "canAddOrder",
    "filterList",
    "hideRemove",
    "hideAdd",
    "showPhone",
    "showFilterList",
    "hideFirstOptions",
  ],
  data() {
    return {
      toggleOptions: false,
      mobileFiltersOpen: false,
      showConfirmPopup: false,
      timerTime: 60,
      saveTimerTime: 60,
      timerToggleLocal: false,
      filterListName: {
        shipment_method_id: "Тип доставки",
        payment_method_id: "Оплата",
        description: "Комментарий",
        employee_id: "Сотрудник",
        comment: "Комментарий",
        department_id: "Отдел",
        order_id: "order_id",
        title: "Название",
        site: "Название",
        domain: "domain",
        phone: "Телефон",
        email: "email",
        name: "Имя",
        ttn: "TTH",
        id: "id",
      },
      toggleTelephone: false,
      employee_id: null,
      department_id: null,
      shipment_method_id: null,
      ordersSearch: "",
    };
  },
  computed: {
    ...mapGetters({
      shipmentMethods: "orders/shipmentMethods",
      paymentMethods: "orders/paymentMethods",
      departments: "catalog/departments",
      timerToggle: "config/timerToggle",
      ordersList: "orders/ordersList",
      menuHide: "config/menuHide",
      combineOrders: "config/combineOrders",
      users: "contacts/users",
      profile: "profile/profile",
      combineCheckedList: "config/combineCheckedList",
      loadingStatus: "config/loadingStatus",
    }),
  },
  watch: {
    timerToggleLocal(e) {
      this.changeTimerToggle(e);
    },
    timerToggle(e) {
      if (e) {
        this.timerBegin();
      }
    },
  },
  methods: {
    toggleMobileFilters() {
      this.mobileFiltersOpen = !this.mobileFiltersOpen;
    },
    getOrderForId: _.debounce(function() {
      this.toggleLoadingStatus(true);
      this.getOrdersList({ search: this.ordersSearch }).then(() => {
        this.toggleLoadingStatus(false);
      });

      this.changeSearchQuery({
        search: this.ordersSearch,
        title: "employee_id",
        id: this.employee_id,
      });
    }, 500),
    activeCombineOrders() {
      this.toggleCombineOrders(!this.combineOrders);
    },
    findDepartmentsOrders() {
      this.employee_id = null;
      this.shipment_method_id = null;
      this.toggleLoadingStatus(true);
      this.changeSearchQuery({
        title: "department_id",
        id: this.department_id,
      });
      this.getOrdersList({ department_id: this.department_id }).then(() => {
        this.toggleLoadingStatus(false);
      });
    },
    clearDepartmentsOrders() {
      this.department_id = null;
      this.toggleLoadingStatus(true);
      this.changeSearchQuery(null);
      this.getOrdersList().then(() => {
        this.toggleLoadingStatus(false);
      });
    },
    findShipmentOrders() {
      this.employee_id = null;
      this.department_id = null;
      this.toggleLoadingStatus(true);
      this.changeSearchQuery({
        title: "shipment_method_id",
        id: this.shipment_method_id,
      });
      this.getOrdersList({ shipment_method_id: this.shipment_method_id }).then(
        () => {
          this.toggleLoadingStatus(false);
        }
      );
    },
    clearShipmentOrders() {
      this.shipment_method_id = null;
      this.toggleLoadingStatus(true);
      this.changeSearchQuery(null);
      this.getOrdersList().then(() => {
        this.toggleLoadingStatus(false);
      });
    },
    findUsersOrders() {
      this.department_id = null;
      this.shipment_method_id = null;
      this.toggleLoadingStatus(true);
      this.changeSearchQuery({ title: "employee_id", id: this.employee_id });
      this.getOrdersList({ employee_id: this.employee_id }).then(() => {
        this.toggleLoadingStatus(false);
      });
    },
    clearUsersOrders() {
      this.employee_id = null;
      this.toggleLoadingStatus(true);
      this.changeSearchQuery(null);
      this.getOrdersList().then(() => {
        this.toggleLoadingStatus(false);
      });
    },
    removeFilterItem(index) {
      this.$emit("removeFilterItem", index);
    },
    getFilterNameType(typeName) {
      let result, key;
      for (key in this.filterListName) {
        if (key === typeName) {
          result = this.filterListName[key];
        }
      }
      return result;
    },
    getFilterName(item) {
      let resultName;
      if (item.type === "employee_id") {
        resultName = this.users.filter(
          (userItem) => +userItem.id === Number(item.query)
        );
        resultName = resultName[0].name;
      } else if (item.type === "payment_method_id") {
        resultName = this.paymentMethods.data.filter(
          (userItem) => +userItem.id === Number(item.query)
        );
        resultName = resultName[0].title;
      } else if (item.type === "shipment_method_id") {
        resultName = this.shipmentMethods.data.filter(
          (userItem) => +userItem.id === Number(item.query)
        );
        resultName = resultName[0].title;
      } else if (item.type === "department_id") {
        resultName = this.departments.data.filter(
          (userItem) => +userItem.id === Number(item.query)
        );
        resultName = resultName[0].title;
      } else {
        resultName = item.query;
      }
      return resultName;
    },
    validateTimer() {
      if (this.timerTime < 30) {
        this.timerTime = 30;
        this.$toasted.error("Минимальное время обновления 30 секунд", {
          duration: 3000,
        });
      }
      this.saveTimerTime = this.timerTime;
    },
    timerBegin() {
      if (this.timerTime >= 1 && this.timerToggle) {
        setTimeout(() => {
          this.timerTime--;
          this.timerBegin();
        }, 1000);
      } else {
        if (this.timerToggle) {
          this.timerTime = this.saveTimerTime;
          this.$emit("timerReady");
          this.timerBegin();
        } else {
          this.timerTime = this.saveTimerTime;
        }
      }
    },
    openOptions() {
      if (this.selectedItem) {
        this.toggleOptions = true;
      }
    },
    setUniteOrders() {
      this.$emit("setUniteOrders");
    },
    openAddPopup() {
      this.$emit("openAddPopup");
    },
    changeItem() {
      if (this.selectedItem.length > 1) {
        return;
      }
      this.$emit("changePopup", this.selectedItem[0]);
    },
    removeItem() {
      this.toggleOptions = false;
      this.$emit("removeItem", this.selectedItem[0].id);
      this.showConfirmPopup = false;
    },
    getAllItemsInTable() {
      this.$emit("getAllItemsInTable");
    },
    cancelCombineOrders() {
      this.clearCombineOrders();
      this.toggleCombineOrders(false);
    },
    ...mapActions({
      getOrdersList: "orders/getOrdersList",
    }),
    ...mapMutations({
      changeTimerToggle: "config/changeTimerToggle",
      toggleCombineOrders: "config/toggleCombineOrders",
      clearCombineOrders: "config/clearCombineOrders",
      confirmCombineOrders: "config/confirmCombineOrders",
      changeSearchQuery: "orders/changeSearchQuery",
      toggleLoadingStatus: "config/toggleLoadingStatus",
    }),
  },
};
