import {mapActions, mapGetters, mapMutations} from 'vuex';
import showPage from '../../../mixins/showPage/index'

export default {
    components:{
        Header: () => import('@/components/header/index.vue'),
        leftBar: () => import('@/components/left-bar/index.vue'),
        Table: () => import('@/components/table/list/index.vue'),
        TableOptions: () => import('@/components/table/options/index.vue'),
        popup: () => import('@/components/popups/contacts/user/index.vue'),
        pagination: () => import('@/components/pagination/index.vue'),
    },
    mixins:[showPage],
    data(){
      return{
          pageId:2,
          fields:[
              {
                  key: 'id',
                  label: 'id'
              },
              {
                  key: 'name',
                  label: this.$t('tables.full-name')
              },
              {
                  key: 'login',
                  label: this.$t('tables.login')
              },
              {
                  key: 'user_group_id',
                  label: this.$t('tables.group')
              },
              {
                  key: 'department',
                  label: this.$t('tables.department')
              },
              {
                  key: 'email',
                  label: 'Email'
              },
              {
                  key: 'description',
                  label: this.$t('tables.description')
              },
          ],
          items:[],
          selectedItem: null,
          showPopup: false
      }
    },
    computed:{
      ...mapGetters({
          menuHide:'config/menuHide',
          usersGroup:'contacts/usersGroup',
          users:'contacts/users'
      })
    },
    watch:{
        users(e){
            this.items = []
            e.map(item=>{
                let resultGroup = this.usersGroup.data.filter(itemGroup => itemGroup.id === item.user_group_id)
                resultGroup = resultGroup[0];
                let departmentList = '';
                let maxStringLength = false
                if(item.departments.length){
                    item.departments.map(itemDepartment =>{
                        if(departmentList.length < 32) {
                            departmentList += itemDepartment.title + ', '
                        }else if(departmentList.length > 32 && !maxStringLength){
                            departmentList.slice(32)
                            departmentList += '....'
                            maxStringLength = true;
                        }
                    })
                }
                this.items.push({
                    id:item.id,
                    name: item.name,
                    login: item.login,
                    user_group_id: resultGroup.title,
                    email: item.email,
                    description: item.description,
                    department: departmentList,
                });
            })
            this.res = e;
        },
    },
    created() {
        this.getUsersGroup().then(()=>{
        })
    },
    methods:{
        getSearchInfo(data){
            if(data.query){
                this.getUsers({[data.type]: data.query});
            }else{
                this.getUsers();
            }
        },
        getAllItemsInTable(){
            this.selectedItem = this.items;
            this.$refs.tableComponent.getAllItemsInTable();
        },
        changePopup(item){
            this.changeUser(item.id)
            this.showPopup = true;
        },
        selectItem(item){
            if(item.length){
                this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        sendUser(form){
            this.postSignUp(form).then(res=>{
                if(res.status){
                    this.getUsers();
                    this.$toasted.success('Пользователь успешно добавлен',{
                        duration : 3000
                    });
                    this.showPopup = false;
                }else{
                    let key
                    for(key in res.data.errors){

                        this.$toasted.error(res.data.errors[key],{
                            duration : 3000
                        });
                    }
                }
            })
        },
        changeUserForm(form){
            this.editUser(form).then(res=>{
                if(res.status){
                    this.getUsers();
                    this.$toasted.success('Пользователь успешно изменен',{
                        duration : 3000
                    });
                    this.showPopup = false;
                }
            })
        },
        ...mapActions({
            getUsersGroup:'contacts/getUsersGroup',
            getUsers:'contacts/getUsers',
            showUser:'contacts/showUser',
            editUser:'contacts/editUser',
            postSignUp:'auth/postSignUp',
        }),
        ...mapMutations({
            changeUser:'contacts/changeUser'
        })
    }
}
