import axios from "axios";

axios.defaults.headers = {
    'Authorization':'Bearer ' + localStorage.getItem('token'),
    'Accept':'application/json',
}

const state = {
    warehouses: null,
    warehouse: null,
    provisioners: null,
    provisioner: null,
    warehousesProducts: null,
};

const getters ={
    warehouse: state => state.warehouse,
    warehouses: state => state.warehouses,
    provisioners: state => state.provisioners,
    provisioner: state => state.provisioner,
    warehousesProducts: state => state.warehousesProducts,
};
const actions = {

    //begin Warehouses

    getWarehouses: async ({commit}, options) =>{
        let url = '/warehouse'
        if(options) {
            url = `/warehouse?page=${options.page}&per_page=${options.per_page}`
        }
        try{
            const result = await axios.get(url);
            commit('changeGetWarehouse', result.data.models);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    addWarehouse: async ({commit}, payload) =>{
        try{
            const result = await axios.post('/warehouse', payload);
            commit('changeAddWarehouse', result.data.model);
            return {
                status: true,
                data: result.data,
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    showWarehouse: async ({commit}, id) =>{
        try{
            const result = await axios.get('/warehouse/'+id);
            commit('changeShowWarehouse', result.data.model);
            return {
                status: true,
                data: result.data,
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    editWarehouse: async ({commit}, payload) =>{
        try{
            const result = await axios.put('/warehouse/'+payload.id, payload);
            commit('changeWarehouse', result.data.model);
            return {
                status: true,
                data: result.data,
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    removeWarehouse: async ({commit}, id) =>{
        try{
            const result = await axios.post('/warehouse/'+id, {
                "_method" : "DELETE"
            });
            commit('changeAddWarehouse', result.data.model);
            return {
                status: true,
                data: result.data,
            }
        }catch (e){
            if(e.response.status === 302){
                return {
                    status: true,
                    data: e.response.data,
                }
            }
            throw e
        }
    },


    //begin Provisioners

    getProvisioners: async ({commit}, options) => {
        let url = '/provisioners'
        if(options) {
            url = `/provisioners?page=${options.page}&per_page=${options.per_page}`
        }
        try{
            const result = await axios.get(url);
            commit('changeProvisioners', result.data.models)
        }catch (e){
            console.log(e);
            throw e;
        }
    },
    addProvisioners: async ({commit}, payload) => {
        try{
            const result = await axios.post('/provisioners', payload);
            // console.log(result)
            commit('changeShowProvisioner', result.data.models)
            return {
                status: true,
                data: result.data,
            }
        }catch (e){
            console.log(e);
            throw e;
        }
    },
    showProvisioner: async ({commit}, id) => {
        try{
            const result = await axios.get('/provisioners/'+id);
            commit('changeShowProvisioner', result.data.model)
            return {
                status: true,
                data: result.data,
            }
        }catch (e){
            console.log(e);
            throw e;
        }
    },
    editProvisioner: async ({commit}, payload) =>{
        try{
            const result = await axios.put('/provisioners/'+payload.id, payload);
            commit('changeWarehouse', result.data.model);
            return {
                status: true,
                data: result.data,
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    removeProvisioner: async ({commit}, id) =>{
        try{
            const result = await axios.post('/provisioners/'+id, {
                "_method" : "DELETE"
            });
            commit('changeAddWarehouse', result.data.model);
            return {
                status: true,
                data: result.data,
            }
        }catch (e){
            if(e.response.status === 302){
                return {
                    status: true,
                    data: e.response.data,
                }
            }
            throw e
        }
    },

    getWarehousesProducts: async ({commit}, options) =>{
        const links = options.links ? options.links : '';
        let url = `/warehouse/${options.id}/products?page=${options.page}&per_page=${options.per_page}`+links
        if(options.search){
            url = `/warehouse/${options.id}/products?id=${options.product_id}&title=${options.name}&sku=${options.sku}`
        }
        try{
            const result = await axios.get(url)
            commit('changeGetWarehouseProducts', result.data.models);
        }catch (e){
            console.log(e)
            throw e
        }
    },

}


const mutations ={
    changeGetWarehouse(state, status){
        state.warehouses = status
    },
    changeAddWarehouse(state, status){
        state.warehouses.data.push({...status})
    },
    changeProvisioners(state, status){
        state.provisioners = status;
        // for(let key in status){
        //     state.provisioners.push(status[key]);
        // }
    },
    changeShowProvisioner(state, status){
        state.provisioner = status;
    },
    changeShowWarehouse(state, status){
        state.warehouse = status;
    },
    changeWarehouse(){
    },
    changeGetWarehouseProducts(state, status){
        state.warehousesProducts = status
    },
};



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
