import {mapGetters, mapActions, mapMutations} from 'vuex';
import showPage from "../../../mixins/showPage";

export default {
    components: {
        Header: () => import('@/components/header/index.vue'),
        leftBar: () => import('@/components/left-bar/index.vue'),
        TableOptions: () => import('@/components/table/options/index.vue'),
        Table: () => import('@/components/table/list/index.vue'),
        popupProduct: () => import('@/components/popups/catalog/product/index.vue'),
    },
    mixins:[showPage],
    data() {
        return {
            pageId:34,
            fields: [
                {
                    key: 'id',
                    label: 'id'
                },
                {
                    key: 'photo',
                    label: 'Фото',
                },
                {
                    key: 'title',
                    label: 'Название',
                },
                {
                    key: 'model',
                    label: 'Модель',
                },
                {
                    key: 'lack_less_quantity',
                    label: 'Остаток',
                },
                {
                    key: 'lack_less_min_quantity',
                    label: 'Мин. Остаток',
                },
                {
                    key: 'category',
                    label: 'Категория',
                },
                {
                    key: 'priceList',
                    label: 'Цена (база)',
                },
                {
                    key: 'department',
                    label: 'Отдел',
                },
                {
                    key: 'valueIn',
                    label: 'В заказах',
                },
                {
                    key: 'manufacturer',
                    label: 'Производитель',
                },
                {
                    key: 'lackoFReady',
                    label: 'Статус',
                },
            ],
            items: [],
            selectedItem: null,
        }
    },
    computed: {
        ...mapGetters({
            menuHide: 'config/menuHide',
            products: 'catalog/products',
            showProductPopup: 'catalog/showProductPopup',
            leftLovers: 'catalog/leftLovers',
        })
    },
    watch: {
        leftLovers(e) {
            this.items = []
            this.createTableItems(e)
            this.res = e
        },
    },
    created() {
        this.toggleLoadingStatus(true);
        this.getLeftLovers().then(()=>{
            this.toggleLoadingStatus(false);
        });
    },
    methods: {
        createTableItems(e) {
            this.items = []
            e.map(item => {
                this.items.push(
                    {
                        id: item.id,
                        'photo' : item.images,
                        'title' : item && item.title ? item.title : '',
                        'category' : item.category && item.category.tiitle ? item.category.title : "",
                        'priceList' : Number(item.sale_price).toFixed(1),
                        'department': item.department && item.department ? item.department.title : "",
                        'model' : item.model && item.model.title ? item.model.title : '',
                        'lack_less_quantity': item.less_quantity ? item.less_quantity: '',
                        'lack_less_min_quantity': item.minimum ? item.minimum: '',
                        'manufacturer' : item.manufacturer && item.manufacturer.title ? item.manufacturer.title : '',
                        'value' : item.current_less_quantity,
                        'valueIn' : item.in_orders_quantity,
                        'lackoFReady': 1
                    })
            })
        },
        sendLackOfItem(id) {
            let payload = [];
            payload.push(id);
            this.toggleLoadingStatus(true);
            this.changeLeftLovers(payload).then(()=>{
                this.getLeftLovers().then(()=>{
                    this.toggleLoadingStatus(false);
                });
            })
        },
        ...mapActions({
            getLeftLovers: 'catalog/getLeftLovers',
            changeLeftLovers: 'catalog/changeLeftLovers',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        })
    },
}
