import {mapGetters, mapActions, mapMutations} from 'vuex';
import paginationMixin from '@/mixins/pagination/index.js'
import showPage from "../../../mixins/showPage";

export default {
    components:{
        Header: ()=> import('@/components/header/index.vue'),
        leftBar: ()=> import('@/components/left-bar/index.vue'),
        Table: ()=> import('@/components/table/list/index.vue'),
        pagination: ()=> import('@/components/pagination/index.vue'),
        TableOptions: ()=> import('@/components/table/options/index.vue'),
        popup: ()=> import('@/components/popups/orders/typeOrders/index.vue'),
    },
    mixins: [paginationMixin, showPage],
    data(){
        return{
            pageId:8,
            fields:[
                {
                    key:'id',
                    label:'id',
                },
                {
                    key:'title',
                    label: this.$t('tables.title')
                },
                {
                    key:'sort',
                    label: this.$t('tables.sort')
                },
            ],
            items:[],
            selectedItem: null,
            showPopup: false,
        }
    },
    computed:{
        ...mapGetters({
            menuHide:'config/menuHide',
            paymentMethods:'orders/paymentMethods',
        })
    },
    watch:{
        paymentMethods(e){
            this.items = [];
            e.data.map(item=>{
                this.items.push({'id': item.id,'title': item.title, 'sort': item.sort})
            })
            this.res = e;
        },
        page(){
            this.getPaymentMethods({page: this.page, per_page: this.per_page});
        },
        per_page(){
            this.getPaymentMethods({page: this.page, per_page: this.per_page});
        },

    },
    created() {
        this.toggleLoadingStatus(true);
        this.getPaymentMethods({page: 1, per_page: this.per_Page}).then(()=>{
            this.toggleLoadingStatus(false);
        })
    },

    methods:{
        getAllItemsInTable(){
            this.selectedItem = this.items;
            this.$refs.tableComponent.getAllItemsInTable();
        },
        sendTypeOrder(item){
            this.addPaymentMethod(item).then(res=>{
                if(res.status){
                    this.items = [];
                    this.$toasted.success('Статкс успешно добавлен', {
                        duration: 3000
                    });
                    this.showPopup = false;
                    this.getPaymentMethods({page: 1, perpage: this.perPage});
                }
            });
        },
        changeTypeOrder(item){
            this.editPaymentMethod(item).then(res=>{
                if(res.status){
                    this.items = [];
                    this.$toasted.success('Способ успешно изменен', {
                        duration: 3000
                    });
                    this.showPopup = false;
                    this.getPaymentMethods({page: 1, perpage: this.perPage});
                }
            });
        },
        editClickItem(item){
            this.showPaymentMethod(item.id)
            this.showPopup = true;
        },
        removeItem(id){
            this.removePaymentMethod(id).then(res=>{
                if(res.status){
                    this.items = [];
                    this.getPaymentMethods({page: 1, perpage: this.perPage});
                    this.$toasted.success('Тип успешно удален',{
                        duration : 3000
                    });
                    this.showPopup = false;
                }
            })
        },
        toggleStatus(item){
            const payload = {
                id: item.id,
                data:{
                    title:item.title,
                    status:Boolean(item.status)
                }
            }
            this.editPaymentMethod(payload)
        },
        selectItem(item){
            if(item.length){
                this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        ...mapActions({
            getPaymentMethods:'orders/getPaymentMethods',
            showPaymentMethod:'orders/showPaymentMethod',
            addPaymentMethod:'orders/addPaymentMethod',
            editPaymentMethod:'orders/editPaymentMethod',
            removePaymentMethod:'orders/removePaymentMethod',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        })
    }
}
