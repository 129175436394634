export default {
    data(){
        return{
            filterList:[],
            searchPayload:{},
        }
    },
    methods: {
        getSearchInfo(data){
            console.log('data -' , data);
            if(data.type === 'mobile'){
                data.type = 'phone'
            }
            else if(data.type === 'priceList'){
                data.type = 'sale_price'
            }
            else if(data.type === 'users_title'){
                data.type = 'title'
            }
            else if(data.type === 'order_phone'){
                data.type = 'phone'
            }
            if(this.filterList.length) {
                const searchIndex = this.filterList.findIndex(item => item.type === data.type);
                if(searchIndex !== -1 && data.query.length){
                    this.filterList[searchIndex].query = data.query;
                }else if(searchIndex !== -1 && !data.query.length){
                    this.filterList.splice(searchIndex, 1);
                }else if(searchIndex === -1 && data.query.length){
                    this.filterList.push(data)
                }
            }
            else {
                this.filterList.push(data)
            }
            this.createPayloadForSearch();
        },
        createPayloadForSearch(){
            this.searchPayload = {}
            const filterDataList = {};
            this.filterList.map(item =>{
                filterDataList[item.type] = item.query
            });
            this.searchPayload = {
                ...filterDataList,
                page: this.page,
                per_page: this.per_page,
            };
            this.getPayloadForSearch();
        }
    }
}
