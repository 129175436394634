import {mapGetters, mapActions, mapMutations} from 'vuex';
import paginationMixin from '@/mixins/pagination/index.js'
import showPage from "../../../mixins/showPage";

export default {
    components:{
        Header: () => import('@/components/header/index.vue'),
        leftBar: () => import('@/components/left-bar/index.vue'),
        Table: () => import('@/components/table/list/index.vue'),
        pagination: () => import('@/components/pagination/index.vue'),
        productMaterial: () => import('@/components/popups/catalog/material/index.vue'),
        TableOptions: () => import('@/components/table/options/index.vue')
    },
    mixins: [paginationMixin, showPage],
    data(){
        return{
            pageId:17,
            fields:[
                {
                    key: 'id',
                    label: 'ID'
                },
                {
                    key:'title',
                    label: this.$t('tables.title')
                },
                {
                    key:'status',
                    label: this.$t('tables.status')
                },
                {
                    key:'sort',
                    label: this.$t('tables.sort'),
                    sortable: true
                },
            ],
            items:[],
            selectedItem: null,
            showMaterialPopup: null,
        }
    },
    computed:{
        ...mapGetters({
            menuHide:'config/menuHide',
            materials:'catalog/materials',
        })
    },
    watch:{
        materials(e){
            this.items = []
            e.data.map(item=>{
                this.items.push({id:item.id, 'title': item.title, 'status': Boolean(item.status), 'sort': item.sort})
            })
            this.res = e
        },
        page(e) {
            this.getMaterials({page: e, per_page: this.per_page});
        },
        per_page(e) {
            this.getMaterials({page: this.page, per_page: e});
        }
    },
    created() {
        this.toggleLoadingStatus(true);
        this.getMaterials({page: 1, per_page: this.per_page}).then(()=>{
            this.toggleLoadingStatus(false);
        })
    },
    methods:{
        getSearchInfo(data){
            if(data.query){
                this.getMaterials({[data.type]: data.query,page: this.page, per_page: this.per_page});
            }else{
                this.getMaterials({page: this.page, per_page: this.per_page});
            }
        },
        getAllItemsInTable(){
            this.selectedItem = this.items;
            this.$refs.tableComponent.getAllItemsInTable();
        },
        sendMaterial(item){
            this.addMaterial(item).then(res=>{
                if(res.status){
                    this.items = [];
                    this.$toasted.success('Материал успешно добавлен', {
                        duration: 3000
                    });
                    this.showMaterialPopup = false;
                    this.getMaterials();
                }
            });
        },
        changeMaterial(item){
            this.editMaterial(item).then(res=>{
                if(res.status){
                    this.items = [];
                    this.$toasted.success('Материал успешно обновлен', {
                        duration: 3000
                    });
                    this.showMaterialPopup = false;
                    this.getMaterials();
                }
            });
        },
        selectItem(item){
            if(item.length){
                this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        toggleStatus(item){
            const payload = {
                id: item.id,
                data:{
                    title:item.title,
                    status:item.status
                }
            }
            this.editMaterial(payload)

        },
        editClickMaterial(item){
            this.showMaterial(item.id)
            this.showMaterialPopup = true;
        },
        removeItem(id){
            this.removeMaterial(id).then(res=>{
                if(res.status){
                    this.items = [];
                    this.getMaterials();
                    this.$toasted.success('Материал успешно удален',{
                        duration : 3000
                    });
                    this.showMaterialPopup = false;
                }
            })
        },
        ...mapActions({
            getMaterials:'catalog/getMaterials',
            showMaterial:'catalog/showMaterial',
            editMaterial:'catalog/editMaterial',
            addMaterial:'catalog/addMaterial',
            removeMaterial:'catalog/removeMaterial',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        })

    },
}
