import {mapActions, mapGetters, mapMutations} from 'vuex';
import paginationMixin from '@/mixins/pagination/index.js'
import showPage from "../../../mixins/showPage";

export default {
    components:{
        Header: () => import('@/components/header/index.vue'),
        leftBar: () => import('@/components/left-bar/index.vue'),
        Table: () => import('@/components/table/list/index.vue'),
        pagination: () => import('@/components/pagination/index.vue'),
        popup: () => import('@/components/popups/templates/smsTemplates/index.vue'),
        TableOptions: () => import('@/components/table/options/index.vue')
    },
    mixins: [paginationMixin, showPage],
    data(){
        return{
            pageId:29,
            fields:[
                {
                    key: 'id',
                    label: 'ID'
                },
                {
                    key: 'sms_title',
                    label: this.$t('tables.title')
                },
                {
                    key: 'text',
                    label: this.$t('tables.text')
                },
                {
                    key: 'type',
                    label:  this.$t('tables.type')
                },
            ],
            items:[],
            showWarehousePopup: false,
            selectedItem: null,
        }
    },
    computed: {
        ...mapGetters({
            menuHide:'config/menuHide',
            smsTemplates:'templates/smsTemplates',
        })
    },
    watch:{
        smsTemplates(e){
            this.items = []
            e.data.map(item=>{
                this.items.push({
                    id:item.id,
                    'sms_title': item.title,
                    'text': item.text,
                    'type': item.type ? 'Кирилица' : 'Латиница',
                });
            })
            this.res = e
        },
        page(e) {
            this.getSmsTemplates({page: e, perpage: this.perPage});
        },
        per_page(e) {
            this.getSmsTemplates({page: this.page, perpage: e});
        }
    },
    created() {
        this.toggleLoadingStatus(true);
        this.getOrderStatuses();
        this.getSmsTemplates({page: 1, perpage: this.perPage}).then(()=>{
            this.toggleLoadingStatus(false);
        });
    },
    methods:{
        getAllItemsInTable(){
            this.selectedItem = this.items;
            this.$refs.tableComponent.getAllItemsInTable();
        },
        changePopup(item){
            this.showSmsTemplate(item.id)
            this.showWarehousePopup = true;
        },
        editClickWarehouse(item){
            this.showWarehouse(item.id)
            this.showWarehousePopup = true;
        },
        selectItem(item){
            if(item.length){
                this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        sendSmsTemplate(form){
            this.addSmsTemplate(form).then(res=>{
                if(res.status){
                    this.getSmsTemplates();
                    this.$toasted.success('Шаблон успешно добавлен',{
                        duration : 3000
                    });
                }
                this.showWarehousePopup = false;
            })
        },
        changeSmsTemplate(form){
            console.log('form - ', form);
            this.editSmsTemplate(form).then(res=>{
                if(res.status){
                    this.getSmsTemplates();
                    this.$toasted.success('Шаблон успешно изменен',{
                        duration : 3000
                    });
                    this.showWarehousePopup = false;
                }
            })
        },
        removeItem(id){
          this.removeSmsTemplate(id).then(res=>{
              if(res.status){
                  this.getSmsTemplates();
                  this.$toasted.success('Шаблон успешно удален',{
                      duration : 3000
                  });
                  this.showWarehousePopup = false;
              }
          })
        },
        ...mapActions({
            getSmsTemplates:'templates/getSmsTemplates',
            showSmsTemplate:'templates/showSmsTemplate',
            addSmsTemplate:'templates/addSmsTemplate',
            editSmsTemplate:'templates/editSmsTemplate',
            removeSmsTemplate:'templates/removeSmsTemplate',
            getOrderStatuses: 'orders/getOrderStatuses',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        })
    }
}
