import Vue from 'vue'
import App from './App.vue'
import Vuex from 'vuex'
import axios from "axios";
import router from './router';
import store from './store';
import VueMask from 'v-mask'
import Toasted from 'vue-toasted';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import ToggleButton from 'vue-js-toggle-button'
import vSelect from 'vue-select'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-select/dist/vue-select.css';
import ImageUploader from 'vue-image-upload-resize'
import moment from 'moment'
import i18n from './i18n'
import wysiwyg from "vue-wysiwyg";
import VueApexCharts from 'vue-apexcharts'
import "vue-wysiwyg/dist/vueWysiwyg.css";
import RangeSlider from 'vue-range-slider'
import 'vue-range-slider/dist/vue-range-slider.css'
import vuePusher from 'vue-pusher'
// import VueFbCustomerChat from 'vue-fb-customer-chat'


// import VueSocketIO from 'vue-socket.io'

// import VueMoment from 'vue-moment'

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Vuex);
Vue.use(Toasted);
Vue.use(Vuelidate)
Vue.use(require('vue-moment'));
Vue.use(ToggleButton)
Vue.component('v-select', vSelect)
Vue.use(ImageUploader);
Vue.use(moment);
Vue.use(VueMask)
Vue.use(VueApexCharts)
Vue.use(wysiwyg, {
  image: false
});

Vue.use(vuePusher, {
  api_key: '9baf79be06ca3cf6bac8',
  options: {
    cluster: 'eu',
    encrypted: true,
  }
});

Vue.component('apexchart', VueApexCharts)
Vue.component('RangeSlider', RangeSlider)


Vue.config.productionTip = false
axios.defaults.baseURL = process.env.VUE_APP_API;


new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
