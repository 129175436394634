// import {required} from 'vuelidate/lib/validators'
import { mapGetters, mapActions } from 'vuex';
import NotificationItem from './notification-item/index.vue'
import NotificationPopup from '../../popups/remember/index.vue'

const _ = require('lodash');

export default {
    components: {
      NotificationItem,
      NotificationPopup
    },
    props: ['popupType', 'selectedItem'],
    data() {
        return {
            showNotificationPopup: false,
            notifications: null
        }
    },

    computed: {
        ...mapGetters({
            profile:'profile/profile',
        }),
    },
    created() {
        this.getNotificationList();
    },
    watch:{
        profile(){
            this.getNotificationList();
        }
    },
    methods: {
        removeItem(id){
            let profile = _.clone(this.profile);
            let searchItem = profile.options.remember.filter(item => item.notification_id !== id);
            const payload = {
                user_id: this.profile.id,
                options:{
                    remember:[
                        ...searchItem
                    ]
                }
            }
            this.editUser(payload).then(res=>{
                if(res.status){
                    this.getProfile().then(()=>{
                        this.$toasted.success('Напоминание успешно удалено',{
                            duration : 3000
                        });
                        this.closeNotificationPopup();
                    })
                }
            })
        },
        getNotificationList(){
            if(this.profile && this.profile.options && this.profile.options.remember){
                this.notifications = _.clone(this.profile.options.remember);
                this.notifications.sort((a, b)=> a > b);
            }
        },
        createNotification() {
            this.showNotificationPopup = true
        },
        sendNotification(payload) {
            this.addNotification(payload).then( () => {
                this.getNotifications()
                this.$toasted.success('Напоминание успешно создано',{
                    duration : 3000
                });
                this.showNotificationPopup = false
            }).catch( e => console.log(e) )
        },
        changeNotification(payload) {
            this.editNotification(payload).then( () => {
                this.getNotifications()
                this.$toasted.success('Напоминание успешно обновлено',{
                    duration : 3000
                });
                this.showNotificationPopup = false
            }).catch( e => console.log(e) )
        },
        closeNotificationPopup() {
            this.showNotificationPopup = false
        },
        closePopup() {
            this.$emit('closePopup')
        },
        sendModel() {
            if(this.notification){
                const payload = {
                    id: this.notification.id,
                    data:this.form
                }
                this.$emit('changeNotification', payload)
            }else{
                this.$emit('sendNotification', this.form)
            }
        },
        ...mapActions({
            editUser:'contacts/editUser',
            getProfile: 'profile/getProfile',
            getNotifications: 'notification/getNotifications',
            addNotification: 'notification/addNotification',
            editNotification: 'notification/editNotification'
        })
    },
    destroyed() {
        // this.changeNotification(null);
    }
}