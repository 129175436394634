import axios from "axios";
import qs from 'qs';

axios.defaults.headers = {
    'Authorization':'Bearer ' + localStorage.getItem('token'),
    'Accept':'application/json',
}

const state = {
    //product categoriesz
    orderStatusList: null,
    orderStatus: null,
    oneOrderList: null,

    paymentMethods: null,
    paymentMethod: null,

    shipmentMethods: null,
    shipmentMethod: null,

    ordersNewList: null,
    ordersList: null,
    colNewOrders: 0,
    order: null,
    orderAll: null,
    orderNp: null,
    isUpsell: 0,
    registryList: null,
    ordersPhoneList: null,
    ordersOptions: null,
    ttnNotification: null,
    ordersLength: null,

    ukrposhtaData: null,
    ukrposhtaAddressList: null,

    deliveryAreas: null,
    deliveryCity: null,
    deliveryWarehouses: null,
    searchQuery: null,
    orderDepartmentId: null,
    prevUserOrder: null,
    orderFileLink: null,

    wholesale_order_status: false,
};

const getters = {
    //product categories
    orderStatusList: state => state.orderStatusList,
    orderStatus: state => state.orderStatus,
    oneOrderList: state => state.oneOrderList,

    paymentMethods: state => state.paymentMethods,
    paymentMethod: state => state.paymentMethod,

    shipmentMethods: state => state.shipmentMethods,
    shipmentMethod: state => state.shipmentMethod,

    ordersNewList: state => state.ordersNewList,
    ordersList: state => state.ordersList,
    colNewOrders: state => state.colNewOrders,
    ordersPhoneList: state => state.ordersPhoneList,
    order: state => state.order,
    orderAll: state => state.orderAll,
    ordersLength: state => state.ordersLength,

    isUpsell: state => state.isUpsell,

    registryList: state => state.registryList,

    ordersOptions: state => state.ordersOptions,

    ttnNotification: state => state.ttnNotification,

    ukrposhtaData: state => state.ukrposhtaData,
    ukrposhtaAddressList: state => state.ukrposhtaAddressList,

    deliveryAreas: state => state.deliveryAreas,
    deliveryCity: state => state.deliveryCity,
    deliveryWarehouses: state => state.deliveryWarehouses,
    searchQuery: state => state.searchQuery,
    orderDepartmentId: state => state.orderDepartmentId,
    prevUserOrder: state => state.prevUserOrder,
    orderFileLink: state => state.orderFileLink,

    wholesale_order_status: state => state.wholesale_order_status,
};
const actions = {

    //begin order-status

    getOrderStatuses: async ({commit}, options) =>{
        try{
            const result = await axios.get('/order_statuses',{
                params: options,
                paramsSerializer: (params) =>{
                    return qs.stringify(params);
                }
            });
            commit('changeOrderStatusList', result.data.models);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    getOrderFileLinks: async ({commit}, id) =>{
        try{

            const result = await axios.get(`/orderInvoice/${id}`);
            commit('changeOrderFileLink', result.data);
            return{
                status: true,
                data: result.data
            }
        }catch (e){
            console.log('false', e.response.data)
            return{
                status: false,
                data: e.response.data
            }
        }
    },

    showOrderStatus: async ({commit}, id) =>{
        try{
            const result = await axios.get('/order_statuses/'+id);
            commit('changeOrderStatus', result.data.models);
        }catch (e){
            console.log(e)
            throw e
        }
    },

    addOrderStatus: async ({commit}, payload) =>{
        try{
            const result = await axios.post('/order_statuses', payload);
            commit('changes', result.data);
            return {
                status: true,
                data: result
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },

    editOrderStatus: async ({commit}, payload) =>{
        try{
            const result = await axios.put('/order_statuses/'+payload.id, payload.data);
            commit('changes', result.data.model);
            return {
                status: true,
                data: result.data
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    changeOrdersStatus: async (ctx, payload) =>{
        try{
            const result = await axios.post('/orders/update_orders_status', payload);
            return {
                status: true,
                data: result.data
            }
        }catch (e){
            return {
                status: false,
                data: e.response.data
            }
        }
    },

    removeOrderStatus: async ({commit}, id) =>{
        try{
            const result = await axios.post('/order_statuses/'+id,{
                "_method" : "DELETE"
            });
            commit('changes', result.data);
            return {
                status: true,
                data: result
            }
        }catch (e){
            if(e.response.status === 302){
                return {
                    status: true,
                    data: e.data
                }
            }else{
                console.log(e);
            }
        }
    },

    //begin payment methods

    getPaymentMethods: async ({commit}, options) =>{
        let url = '/payment_methods'
        if(options) {
            url = `/payment_methods`
        }
        try{
            const result = await axios.get(url);
            commit('changePaymentMethods', result.data.models);
        }catch (e){
            console.log(e)
            throw e
        }
    },

    showPaymentMethod: async ({commit}, id) =>{
        try{
            const result = await axios.get('/payment_methods/'+id);
            commit('changePaymentMethod', result.data.models);
        }catch (e){
            console.log(e)
            throw e
        }
    },

    addPaymentMethod: async ({commit}, payload) =>{
        try{
            const result = await axios.post('/payment_methods/', payload);
            commit('changes', result.data);
            return {
                status: true,
                data: result
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },

    editPaymentMethod: async ({commit}, payload) =>{
        try{
            const result = await axios.put('/payment_methods/'+payload.id, payload.data);
            commit('changes', result.data.model);
            return {
                status: true,
                data: result.data
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },

    removePaymentMethod: async ({commit}, id) =>{
        try{
            const result = await axios.post('/payment_methods/'+id,{
                "_method" : "DELETE"
            });
            commit('changes', result.data);
            return {
                status: true,
                data: result
            }
        }catch (e){
            if(e.response.status === 302){
                return {
                    status: true,
                    data: e.data
                }
            }else{
                console.log(e);
            }
        }
    },

    getShipmentMethods: async ({commit}) =>{
        try{
            const result = await axios.get('/shipment_methods');
            commit('changeShipmentMethods', result.data.models);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    showShipmentMethods: async ({commit}, id) =>{
        try{
            const result = await axios.get('/shipment_methods/'+id);
            commit('changeShipmentMethod', result.data.models);
        }catch (e){
            console.log(e)
            throw e
        }
    },

    addShipmentMethods: async ({commit}, payload) =>{
        try{
            const result = await axios.post('/shipment_methods', payload);
            commit('changes', result.data);
            return {
                status: true,
                data: result
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },

    editShipmentMethods: async ({commit}, payload) =>{
        try{
            const result = await axios.put('/shipment_methods/'+payload.id, payload.data);
            commit('changes', result.data.model);
            return {
                status: true,
                data: result.data
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },

    removeShipmentMethods: async ({commit}, id) =>{
        try{
            const result = await axios.post('/shipment_methods/'+id,{
                "_method" : "DELETE"
            });
            commit('changes', result.data);
            return {
                status: true,
                data: result
            }
        }catch (e){
            if(e.response.status === 302){
                return {
                    status: true,
                    data: e.data
                }
            }else{
                console.log(e);
            }
        }
    },

    removeProductInOrder: async (ctx, id) => {
        try{
            const result = await axios.delete('/order_products/'+id, )
            return {
                result:result.data,
                status:true,
            }
        }catch (e){
            if(e.response.status === 302){
                return {
                    status: true,
                    data: e.data
                }
            }else{
                return {
                    result:e.response,
                    status:false,
                }
            }
        }
    },

    //begin orders

    getOrdersList: async ({commit}, options) => {
        try{
            const result = await axios.get('/ordersIndexGroupByStatus', {
                params: options,
                paramsSerializer: (params) => {
                    return qs.stringify(params)
                }
            });
            if(options && options.order_status_id){
                commit('changeOneOrderList', result.data.models);
            }else{
                commit('changeOrdersNewList', result.data.models);
            }
        }catch (e) {
            console.log(e);
            throw e
        }
    },

    getOrders: async ({commit}, options) =>{
        try{
            const result = await axios.get('/orders',{
                params: options,
                paramsSerializer: (params) =>{
                    return qs.stringify(params);
                }
            });
            commit('changeOrdersList', result.data.models);
            commit('changeOrdersOptions', options);
            const resultAll = await axios.get('/orders?per_page=10000');
            commit('changeOrderAll', resultAll.data.models)

            return {
                status: true,
                data: result.data
            }
        }catch (e){
            return {
                status: false,
                data: e.response.data
            }
        }
    },

    getOrdersLength: async ({commit}, id) => {
        try {
            const result = await axios.post('/count_orders_by_status', {id: id})
            commit('changeOrdersLength', result.data.models)
        }
        catch (e){
            return {
                status: false,
                data: e.response.data
            }
        }
    },

    getOrdersForPhone: async ({commit}, options) =>{
        try{
            const result = await axios.get('/orders',{
                params: options,
                paramsSerializer: (params) =>{
                    return qs.stringify(params);
                }
            });
            commit('changeOrdersPhoneList', result.data.models);
        }catch (e){
            console.log(e)
            throw e
        }
    },

    getOrdersForSelect: async ({commit}, options) =>{
        let url = '/orders'
        if(options) {
            url = `/orders`
        }
        try{
            const result = await axios.get(url);
            commit('changeOrdersList', result.data.models);
        }catch (e){
            console.log(e)
            throw e
        }
    },

    showOrder: async ({commit}, id) =>{
        try{
            const result = await axios.get('/orders/'+id);
            commit('changeOrder', result.data.models);
            return{
                status: true,
                data: result.data.models
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    editOrder: async ({commit}, payload) =>{
        try{
            const result = await axios.put('/orders/'+payload.id, payload.data);
            commit('changes', result.data.models);
            return {
                status: true,
                data: result.data
            }
        }catch (e){
            return {
                status: false,
                data: e.response.data
            }
        }
    },
    addOrder: async ({commit}, payload) =>{
        try{
            const result = await axios.post('/orders', payload);
            commit('changes', result.data);
            return {
                status: true,
                data: result
            }
        }catch (e){
            return {
                status: false,
                data: e.response.data
            }
        }
    },
    removeOrder: async ({commit}, id) =>{
        try{
            const result = await axios.post('/orders/'+id,{
                "_method" : "DELETE"
            });
            commit('changes', result.data);
            return {
                status: true,
                data: result
            }
        }catch (e){
            if(e.response.status === 302){
                return {
                    status: true,
                    data: e.data
                }
            }else{
                console.log(e);
            }
        }
    },




    showProductCategory: async ({commit}, id) =>{
        try{
            const result = await axios.get('/categories/'+id);
            commit('changeProductCategory', result.data.model);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    editProductCategory: async ({commit}, payload) =>{
        try{
            const result = await axios.put('/categories/'+payload.id, payload.data);
            commit('changes', result.data.models);
            return {
                status: true,
                data: result.data
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    addProductCategory: async ({commit}, payload) =>{
        try{
            const result = await axios.post('/categories', payload);
            commit('changes', result.data);
            return {
                status: true,
                data: result
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    removeProductCategory: async ({commit}, id) =>{
        try{
            const result = await axios.post('/categories/'+id,{
                "_method" : "DELETE"
            });
            commit('changes', result.data);
            return {
                status: true,
                data: result
            }
        }catch (e){
            if(e.response.status === 302){
                return {
                    status: true,
                    data: e.data
                }
            }else{
                console.log(e);
            }
        }
    },
    addOrderNewPost: async ({commit}, payload) => {
        try {
            const result = await axios.post('/orders/new_post', payload)
            commit('changeOrderNewPost', result.data);
            return{
                status: true,
                data: result.data
            }
        }catch(e){
            return{
                status: false,
                data: e.response.data
            }
        }
    },
    getRegistrylist: async ({commit}) => {
        try {
            const result = await axios.post('/np/registry_list/for_select')
            commit('changeRegistryList', result.data)
        }catch (e){
            return e
        }
    },
    getTtnNotification: async ({commit}, payload) => {
        try {
            const result = await axios.post('/orders/check_ttn', payload)
            commit('changeTtnNotification', result.data)
        }catch (e){
            return e
        }
    },

    // ukrPost search
    getUkrPostIndexData: async ({commit}, payload) => {
        try {
            const result = await axios.post('/ukrpost/search_zipcode', payload);
            commit('changeUkrPostData', result.data)
            return{
                result: true,
                data: result.data
            }
        }catch (e){
            return{
                result: false,
                data: e.response.data
            }
        }
    },
    getUkrPostIndexAddress: async ({commit}, payload) => {
        try {
            const result = await axios.post('/ukrpost/search_street_by_zipcode', payload);
            commit('changeUkrPostAddress', result.data)
            return{
                result: true,
                data: result.data
            }
        }catch (e){
            return{
                result: false,
                data: e.response.data
            }
        }
    },


    // Delivery search

    getDeliveryAreas: async ({commit}) => {
        try{
            const result = await axios.get('/delivery_post/areas')
            commit('changeDeliveryAreas', result.data);
        }catch (e){
            return{
                status: false,
                data: e.response.data
            }
        }
    },
    getDeliveryCities: async ({commit}, ID_REGION) => {
        try{
            const result = await axios.get(`/delivery_post/cities?regionId=${ID_REGION}`)
            commit('changeDeliveryCity', result.data.models);
        }catch (e){
            return{
                status: false,
                data: e.response.data
            }
        }
    },
    getDeliveryWarehouses: async ({commit}, ID_REGION) => {
        try{
            const result = await axios.get(`/delivery_post/warehouses?cityId=${ID_REGION}`)
            commit('changeDeliveryWarehouses', result.data.models);
        }catch (e){
            return{
                status: false,
                data: e.response.data
            }
        }
    },

    orderDropChangePosition: async (ctx, payload) => {
        try{
            await axios.post('/orders/update_orders_status', payload)
            return{
                result: true,
            }
        }catch (e){
            return{
                result: false,
                data: e.response.data
            }
        }
    }
}



const mutations = {
    // status-orders
    changeOrderStatusList(state, status){
        state.orderStatusList = status;
    },
    changeOrderStatus(state, status){
        state.orderStatus = status;
    },

    changePaymentMethods(state, status){
        state.paymentMethods = status;
    },
    changePaymentMethod(state, status){
        state.paymentMethod = status;
    },
    changeShipmentMethods(state, status){
        state.shipmentMethods = status;
    },
    changeShipmentMethod(state, status){
        state.shipmentMethod = status;
    },
    changeOrdersList(state, status){
        state.ordersList = status;
    },
    changeOrdersPhoneList(state, status){
        state.ordersPhoneList = status;
    },
    changeOrder(state, status){
        state.order = null;
        if(status){
            state.order = status;
        }
    },
    showUserOrder(state, data){
        state.prevUserOrder = data
    },
    changeOrderAll(state, status){
        state.orderAll = null;
        state.orderAll = status;
    },
    changeIsUpsell(state, status){
        state.isUpsell = status
    },
    changeOrderNewPost(state, status){
      state.orderNp = status
    },
    changeRegistryList(state, status){
      state.registryList = status;
    },
    changeOrdersOptions(state, options){
        state.ordersOptions = options;
    },
    changeTtnNotification(state, status){
        state.ttnNotification = status;
    },
    changes(){
    },
    changeUkrPostData(state, result){
        state.ukrposhtaData = result;
    },
    changeUkrPostAddress(state, result){
        state.ukrposhtaAddressList = result;
    },
    changeOrdersNewList(state, result){
        state.ordersNewList = result;
    },
    changeOneOrderList(state, result){
        state.oneOrderList = result;
    },
    changeOrdersLength(state, result){
        state.colNewOrders = result;
    },
    changeDeliveryAreas(state, result){
        state.deliveryAreas = result
    },
    changeDeliveryCity(state, result){
        state.deliveryCity = result
    },
    changeDeliveryWarehouses(state, result){
        state.deliveryWarehouses = result
    },
    changeSearchQuery(state, result){
        state.searchQuery = result;
    },
    changeOrderDepartmentId(state, result){
        state.orderDepartmentId = result;
    },
    changeOrderFileLink(state, link){
        state.orderFileLink = link
    },
    changeOrderWholesaleStatus(state, status){
        state.wholesale_order_status = status;
    }
};



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
