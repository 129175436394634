import axios from 'axios';

axios.defaults.headers = {
    'Authorization':'Bearer ' + localStorage.getItem('token'),
    'Accept':'application/json',
}

const state = {
    rate: null,
    cardList: null,
    card: null,
    actualRate: null,
    statements:null,
    monobankCardList:null,
    mainCard:null,
};

const getters ={
    rate: state => state.rate,
    cardList: state => state.cardList,
    card: state => state.card,
    actualRate: state => state.actualRate,
    statements: state => state.statements,
    monobankCardList: state => state.monobankCardList,
    mainCard: state => state.mainCard,
};

const actions = {
    setOneCOrder: async () => {
        try{
      const result = await axios.post('/set_one_c_orders',{
          "orders": [
              {
                  "order_id": 404,
                  "order_status_id": 31,
                  "ttn": ""
              }
          ]
      })
        console.log('result - ', result);
        }catch(e){
            console.log('error request - ', e);
        }
    },
    getRate: async({commit}, options) => {
        let url = '/currencies'
        if(options) {
            url = `/currencies?page=${options.page}&per_page=${options.per_page}`
        }
        try {
            const result = await axios.get(url)
            commit('changeRate', result.data.models)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    getRateActual: async({commit}) => {
        try {
            const result = await axios.get('/currencies/actual')
            commit('changeActualRate', result.data.models)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    sendNewRate: async(ctx, payload) => {
        try {
            const result = await axios.post('/currencies/store', payload)
            return {
                status: true,
                data: result.data
            }
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    getCardList: async({commit}, options) => {
        let url = '/cards'
        if(options) {
            url = `/cards?page=${options.page}&per_page=${options.per_page}`
        }
        try {
            const result = await axios.get(url)
            commit('changeCardList', result.data)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    getOneCard: async({commit}, id) => {
        try {
            const result = await axios.get(`/cards/${id}`)
            commit('getMainCard', result.data)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    getMonobankCardList: async({commit}) => {
        let url = '/monobank/cards'
        try {
            const result = await axios.get(url)
            commit('changeMonobankCardList', result.data)
            return {
                status: true,
                result,
            }
        } catch (e) {
            return {
                status: false,
                e,
            }
        }
    },
    addCard: async(ctx, payload) => {
        try {
            const result = await axios.post('/cards', payload)
            return {
                result: true,
                data:result.data,
            }
        } catch (e) {
            return {
                result: false,
                data:e.response.data,
            }
        }
    },
    changeCard: async(ctx, payload) => {
        try {
            const result = await axios.put(`/cards/${payload.id}`, payload.data)
            return {
                result: true,
                data:result.data,
            }
        } catch (e) {
            return {
                result: false,
                data:e.response.data,
            }
        }
    },
    getStatements: async({commit}, payload) => {
        try {
            const result = await axios.get('/privat/statements?card='+payload.card+'&sd='+payload.to+'&ed='+payload.from+'&account_id='+payload.account_id+'&password='+payload.password+'&only_total='+payload.onlyTotal)
            commit('changeStatements', result)
            return {
                status: true,
                data:result.data,
            }
        } catch (e) {
            return {
                status: false,
                data:e.response.data,
            }
        }
    },
    getMonobankStatements: async({commit}, payload) => {
        try {
            const result = await axios.get('/monobank/statements?card='+payload.card+'&sd='+payload.to+'&ed='+payload.from)
            commit('changeStatements', result)
            return {
                result: true,
                data:result.data,
            }
        } catch (e) {
            return {
                result: false,
                data:e.response.data,
            }
        }
    },
}

const mutations ={
    changeRate(state, status) {
        state.rate = status;
    },
    changeCardList(state, status) {
        state.cardList = [];
        state.cardList = status;
    },
    changeActualRate(state, status) {
        state.actualRate = status;
    },
    changeStatements(state, data){
        state.statements = data;
    },
    changeMonobankCardList(state, data){
        state.monobankCardList = data;
    },
    getCard(state, data){
        if(data){
            state.card = state.cardList.models.data.filter(item => item.id === data)[0];
        }else{
            state.card = null
        }
    },
    getMainCard(state, data){
        state.mainCard = data;
    },

};



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
