import axios from 'axios';

axios.defaults.headers = {
    'Authorization':'Bearer ' + localStorage.getItem('token'),
    'Accept':'application/json',
}

const state = {
    lots: null,
    lot: null,
    transpositions: null,
    transposition: null,
};

const getters ={
    lots: state => state.lots,
    lot: state => state.lot,
    transpositions: state => state.transpositions,
    transposition: state => state.transposition,
};

const actions = {
    getLots: async({commit}, options) => {
        let url = '/lots'
        if(options) {
            url = `/lots?page=${options.page}&per_page=${options.per_page}`
        }
        try {
            const result = await axios.get(url)
            commit('changeLots', result.data.models)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    showLot: async({commit}, id) => {
        try {
            const result = await axios.get('/lots/'+id)
            commit('changeLot', result.data.models)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    addLot: async({commit}, payload) => {
        try {
            const result = await axios.post('/lots', payload)
            commit('changeLot', result.data.models)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    editLot: async({commit}, payload) => {
        try {
            const result = await axios.put('/lots/'+payload.id, payload.data)
            commit('changeLot', result.data.models)
        } catch (e) {
            console.log(e)
            // throw e
        }
    },
    removeLot: async({commit}, id) => {
        try{
            const result = await axios.post('/lots/'+id,{
                "_method" : "DELETE"
            });
            commit('changeLot', result.data.models);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    removeLotProduct: async(ctx, id) => {
        try {
            await axios.post('/lot_products/'+id, {
                '_method': 'DELETE'
            })
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    //transpositions
    getTranspositions: async({commit}, options) =>{
        try{
            const result = await axios.get(`/transpositions?page=${options.page}&per_page=${options.per_page}\``)
            commit('changeTranspositions', result.data.models);
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    showTransposition: async({commit}, id) => {
        try {
            const result = await axios.get('/transpositions/'+id)
            console.log(result)
            commit('changeTransposition', result.data.models)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    addTransposition: async({commit}, payload) => {
        try {
            const result = await axios.post('/transpositions/store', payload)
            commit('changeLot', result.data.models)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    editTransposition: async({commit}, payload) => {
        try {
            const result = await axios.put('/transpositions/'+payload.id, payload.data)
            commit('changeLot', result.data.models)
        } catch (e) {
            console.log(e)
            // throw e
        }
    },
    removeTransposition: async({commit}, id) => {
        try{
            const result = await axios.post('/transpositions/'+id,{
                "_method" : "DELETE"
            });
            commit('change', result.data.models);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    sendWriteOff: async ({commit}, paylaod) => {
        try{
            const result = await axios.post('/lots/write_off', paylaod);
            commit('change' ,result.data.models);
        }catch (e){
            console.log(e);
            throw e;
        }
    }
}

const mutations ={
    changeLots(state, status) {
        state.lots = status;
    },
    changeLot(state, status) {
        state.lot = status;
    },
    //transpositions
    changeTranspositions(state, status){
        state.transpositions = status
    },
    changeTransposition(state, status){
        state.transposition = status
    },
    change(){}

};



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
