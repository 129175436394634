import {mapGetters, mapActions, mapMutations} from "vuex";
import showPage from '../../../mixins/showPage/index'

export default {
    components: {
        Header: () => import('@/components/header/index.vue'),
        leftBar: () => import('@/components/left-bar/index.vue'),
    },
    mixins:[showPage],
    data(){
        return{
            total:0,
            pageId:38,
            form:{
                card:null,
                date:{
                    to:'',
                    from:'',
                }
            },
            fields:[
                {
                    key:'date',
                    label: this.$t('tables.date'),
                },
                {
                    key:'count',
                    label: this.$t('tables.price'),
                },
                {
                    key:'description',
                    label: this.$t('tables.description'),
                },
            ],
            items:[],
            bankType: false,
            buttonTimer:0,
            privatCards: [],
        }
    },
    computed: {
        ...mapGetters({
            cardList: 'rate/cardList',
            users:'contacts/users',
            statements:'rate/statements',
            monobankCardList: 'rate/monobankCardList',
            profile:'profile/profile'
        })
    },
    watch:{
        bankType(e){
          this.form.card = null
          if(e && this.monobankCardList === null){
              this.getMonobankCardList();
          }
        },
    },
    created() {
        this.getCardList().then(()=>{
            this.cardList.models.data.map(item => {
                item.label = item.card +" - "+ item.full_name;
                this.privatCards.push(item);
            })
        });
    },
    methods:{
        returnTime(time){
            let unix_timestamp = time
            const date = new Date(unix_timestamp * 1000);
            return date;
        },
        takeDataForStatements(){
            this.items = [];
            this.total = []
            this.toggleLoadingStatus(true);
            if(this.validData()){
              if(this.bankType){
                  if(this.timeValid()){
                      this.getMonobankStatements({
                          card: this.form.card,
                          to: this.$moment(this.form.date.to).format('DD.MM.YYYY'),
                          from: this.$moment(this.form.date.from).format('DD.MM.YYYY'),
                      }).then(()=>{
                          this.toggleLoadingStatus(false);
                          const statements = this.statements.data.models
                          statements.map(item=>{
                              this.items.push({
                                  date:{
                                      day: this.$moment(new Date(item.time * 1000)).format('DD-MM-YYYY HH:mm'),
                                      time: '',
                                  },
                                  count: item.amount,
                                  description: item.description,
                              })
                          })
                          this.buttonTimer = 10;
                          this.beginButtonTimer()
                      })
                  }else{
                      this.toggleLoadingStatus(false);
                      this.$toasted.error('Промежуток даты не может превышать 30 дней!', {
                          duration: 3000,
                          position: "top-center"
                      })
                  }
              }else{
                  this.getStatements({
                      card: this.form.card.card,
                      account_id: this.form.card.account_id,
                      password: this.form.card.password,
                      to: this.$moment(this.form.date.to).format('DD-MM-YYYY'),
                      from: this.$moment(this.form.date.from).format('DD-MM-YYYY'),
                      onlyTotal: 0,
                  }).then(res=>{
                      if(res.status){
                          const statements = this.statements.data.models.transactions
                          this.total = this.statements.data.models.amount
                          Object.keys(statements).map(item=>{
                              this.items.push({
                                  date:{
                                      day: statements[item].DATE_TIME_DAT_OD_TIM_P,
                                  },
                                  count: statements[item].SUM +" "+ statements[item].CCY,
                                  description: statements[item].OSND,
                              })
                          })
                      }else{
                          this.$toasted.error(JSON.stringify(res.data.message), {
                              duration: 3000,
                              position: "top-center"
                          })
                      }
                      this.toggleLoadingStatus(false);
                  })
              }
          }
        },
        beginButtonTimer(){
            setTimeout(()=>{
                if(this.buttonTimer !== 0){
                    this.buttonTimer--
                    this.beginButtonTimer();
                }
            },1000)
        },
        timeValid(){
            const to = this.$moment(this.form.date.to);
            const from = this.$moment(this.form.date.from);
            if(from.diff(to, 'days') > 31){
                return false
            }else{
                return true
            }
        },
        validData(){
            const form = this.form;
            const date = form.date;
            if(form.card && date.to.length && date.from.length){
                return true
            }else{
                this.toggleLoadingStatus(false);
                this.$toasted.error('Заполните все поля',{
                    position: 'top-center',
                    duration: 2000,
                })
                return false
            }
        },
        ...mapActions({
            getCardList:'rate/getCardList',
            getStatements:'rate/getStatements',
            getMonobankCardList:'rate/getMonobankCardList',
            getMonobankStatements:'rate/getMonobankStatements',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        })
    },
}
