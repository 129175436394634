import {mapActions, mapGetters, mapMutations} from 'vuex';
import paginationMixin from '@/mixins/pagination/index.js'
import showPage from "../../mixins/showPage";

export default {
    components:{
        Header: ()=> import('../../components/header/index.vue'),
        leftBar: ()=> import('../../components/left-bar/index.vue'),
        Table: ()=> import('../../components/table/list/index.vue'),
        TableOptions: ()=> import('../../components/table/options/index.vue'),
        PopupNp: ()=> import('@/components/popups/plugins/nova-poshta/index.vue'),
        PopupTurboSms: ()=> import('@/components/popups/plugins/turboSms/index.vue'),
        PopupBinotel: ()=> import('@/components/popups/plugins/binotel/index.vue'),
        PopupMonobank: ()=> import('@/components/popups/plugins/monobank/index.vue'),
        PopupUkrposhta: ()=> import('@/components/popups/plugins/ukr-poshta/index.vue'),
        PopupDelivery: ()=> import('@/components/popups/plugins/delivery/index.vue'),
        pagination: ()=> import('@/components/pagination/index.vue'),
    },
    mixins: [paginationMixin, showPage],
    data(){
        return{
            pageId:31,
            fields:[
                {
                    key: 'moduleTitle',
                    label: this.$t('tables.title')
                },
                {
                    key: 'module',
                    label: this.$t('tables.module')
                },
                {
                    key: 'description',
                    label: this.$t('tables.description')
                },
                // {
                //     key: 'status',
                //     label: 'Активация'
                // },
            ],
            items:[],
            showPopup: false,
            showPopupTurboSms: false,
            showPopupBinotel: false,
            showPopupMonobank: false,
            showPopupUkrPoshta: false,
            showPopupDelivery: false,
            selectedItem: null,
            list:[
                {
                    np_title:'Нова Пошта',
                    np_module:'nova_poshta',
                    np_description:'Курьерская служба №1 в Украине',
                    np_status:1,
                    np_area:'',
                    np_phone:'',
                    np_firstname:'',
                    np_lastname:'',
                    np_middlename:'',
                    np_api_key:'',
                },
                {
                    np_title:'ТурбоСМС',
                    np_module:'turbosms',
                    np_description:'СМС-Биллинг',
                    np_status:1,
                },
                {
                    np_title:'Monobank',
                    np_module:'monobank',
                    np_description:'Монобанк апі токенг',
                    np_status:1,
                },
                {
                    np_title:'УкрПошта',
                    np_module:'ukrposhta',
                    np_description:'УкрПошта апі токенг',
                    np_status:1,
                },
                {
                    np_title:'Бинотел',
                    np_module:'binotel',
                    np_description:'Бинотел',
                    np_status:1,
                },
                {
                    np_title:'Delivery',
                    np_module:'delivery',
                    np_description:'Delivery',
                    np_status:1,
                },
            ]
        }
    },
    computed: {
        ...mapGetters({
            menuHide:'config/menuHide',
            options:'config/options',
            warehouse:'warehouse/warehouse',
        })
    },
    watch:{
        page(e) {
            this.getWarehouses({page: e, perpage: this.perPage});
        },
        per_page(e) {
            this.getWarehouses({page: this.page, perpage: e});
        },
        options(e){
            if(e.models.length || e.models.length){
                e.models[0].map(item=>{
                    this.items.push({
                        moduleTitle: item.np_title,
                        module: item.np_module,
                        description: item.np_description,
                        // status: Boolean(item.np_status)
                    });
                })
            }else{
                this.items = []

                this.list.map(item=>{
                    this.items.push({
                        moduleTitle: item.np_title,
                        module: item.np_module,
                        description: item.np_description,
                        // status: Boolean(item.np_status)
                    });
                })
            }
            this.res = e
        }
    },
    created() {
        this.toggleLoadingStatus(true);
        this.getWarehouses({page: 1, perpage: this.perPage}).then(()=>{
            this.toggleLoadingStatus(false);
        });
        this.getOptions();
        this.getOrderStatuses();
    },
    methods:{
        changePopup(item){
            this.showOption(item.id)
            if(item.module === 'nova_poshta'){
                this.showPopup = true;
            }else if(item.module === 'turbosms'){
                this.showPopupTurboSms = true;
            }else if(item.module === 'binotel'){
                this.showPopupBinotel = true;
            }else if(item.module === 'monobank'){
                this.showPopupMonobank = true;
            }else if(item.module === 'ukrposhta'){
                this.showPopupUkrPoshta = true;
            }else if(item.module === 'delivery'){
                this.showPopupDelivery = true;
            }
        },
        editClickWarehouse(item){
            this.showWarehouse(item.id)
            this.showPopup = true;
        },
        selectItem(item){
            if(item.length){
                this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        sendOptions(form){
            this.editOptions(form).then(res=>{
                if(res.result){
                    this.getOptions();
                    this.$toasted.success('Модуль успешно изменен',{
                        duration : 3000
                    });
                }
                this.showPopup = false;
            })
        },
        changeOptions(form){
            this.changeOptions(form).then(res=>{
                if(res.status){
                    this.getOptions();
                    this.$toasted.success('Модуль успешно изменен',{
                        duration : 3000
                    });
                    this.showPopup = false;
                }
            })
        },
        removeItem(id){
          this.removeWarehouse(id).then(res=>{
              if(res.status){
                  this.getWarehouses();
                  this.$toasted.success('Плагин успешно удален',{
                      duration : 3000
                  });
                  this.showPopup = false;
              }
          })
        },
        ...mapActions({
            getWarehouses:'warehouse/getWarehouses',
            addWarehouse:'warehouse/addWarehouse',
            showWarehouse:'warehouse/showWarehouse',
            editWarehouse:'warehouse/editWarehouse',
            removeWarehouse:'warehouse/removeWarehouse',
            getOrderStatuses: 'orders/getOrderStatuses',
            getNpAreas:'plugins/getNpAreas',
            getOptions:'config/getOptions',
            editOptions:'config/editOptions',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
            showOption: 'config/showOption',
        })
    }
}
