import {mapGetters, mapActions, mapMutations} from 'vuex';
import paginationMixin from '@/mixins/pagination/index.js'
import showPage from "../../../mixins/showPage";

export default {
    components: {
        Header: () => import('@/components/header/index.vue'),
        leftBar: () => import('@/components/left-bar/index.vue'),
        TableOptions: () => import('@/components/table/options/index.vue'),
        Table: () => import('@/components/table/list/index.vue'),
        productCategoryPopup: () => import('@/components/popups/catalog/productCategory/index.vue'),
        pagination: () => import('@/components/pagination/index.vue')
    },
    mixins: [paginationMixin, showPage],
    data(){
        return{
            pageId:13,
            fields:[
                {
                    key: 'id',
                    label: 'ID'
                },
                {
                    key:'category-title',
                    label: this.$t('tables.title'),
                },
                {
                    key:'status',
                    label: this.$t('tables.status'),
                },
                {
                    key:'sort',
                    label: this.$t('tables.sort'),
                },
            ],
            items:[],
            selectedItem: null,
            showProductCategoryPopup:false,
        }
    },
    computed:{
        ...mapGetters({
            menuHide:'config/menuHide',
            productCategories:'catalog/productCategories',
        })
    },
    watch:{
        productCategories(e){
            this.items = []
            e.data.map(item=>{
                if(item['parent_id'] === null){

                    if(item.children.length){
                        this.items.push({'id':item.id, 'category-title': item.title, 'status': Boolean(item.status), 'sort': item.id, 'childStatus': true, 'childCol':item.children.length})
                        item.children.map(itemChild=>{
                            this.items.push({'id':itemChild.id, 'category-title': itemChild.title, 'status': Boolean(itemChild.status), 'sort': itemChild.id, 'is_child': true})
                        })
                    }else{
                        this.items.push({'id':item.id, 'category-title': item.title, 'status': Boolean(item.status), 'sort': item.id, 'childStatus': false})
                    }
                }
            })
            this.res = e
        },
        page(e) {
            this.getProductCategories({page: e, perpage: this.perPage});
        },
        per_page(e) {
            this.getProductCategories({page: this.page, perpage: e});
        }
    },
    created() {
        this.toggleLoadingStatus(true);
        this.getProductCategories({page: 1, perpage: this.perPage}).then(()=>{
            this.toggleLoadingStatus(false);
        })
    },
    methods:{
        getSearchInfo(data){
            if(this.activeStatus !== 0){
                if(data.query){
                    this.getProductCategories({[data.type]: data.query,page: this.page, perpage: this.perPage, customer_group_id: this.activeStatus});
                }else{
                    this.getProductCategories({page: this.page, perpage: this.perPage, customer_group_id: this.activeStatus});
                }
            }else{
                if(data.query){
                    this.getProductCategories({[data.type]: data.query,page: this.page, perpage: this.perPage});
                }else{
                    this.getProductCategories({page: this.page, perpage: this.perPage});
                }
            }
        },
        getAllItemsInTable(){
            this.selectedItem = this.items;
            this.$refs.tableComponent.getAllItemsInTable();
        },
        sendProductCategory(item){
            let payload = {
                title: item.title,
                parent_id: null,
            }
            if(item.parentSelectCategory){
                payload.parent_id = item.parentSelectCategory.id
            }
            this.addProductCategory(payload).then(res=> {
                if (res.status) {
                    this.items = [];
                    this.$toasted.success('Категория товара успешно добавлена', {
                        duration: 3000
                    });
                    this.showProductCategoryPopup = false;
                    this.getProductCategories();
                }
            });
        },
        changeProductCategory(item){
            const payload = {
                id: item.id,
                data: {
                        title: item.title,
                        parent_id:null,
                        default_width: Number(item.default_width),
                        default_height:Number(item.default_height),
                        default_length:Number(item.default_length),
                        default_weight:Number(item.default_weight),
                        base_price:item.base_price,
                    }
                }
                if(item.parentSelectCategory){
                    payload.data.parent_id = item.parentSelectCategory.id
                }
            this.editProductCategory(payload).then(res=> {
                if (res.status) {
                    this.items = [];
                    this.$toasted.success('Категория товара успешно изменена', {
                        duration: 3000
                    });
                    this.showProductCategoryPopup = false;
                    this.getProductCategories();
                }
            });
        },
        editClickProductCategoty(item){
            this.showProductCategory(item.id)
            this.showProductCategoryPopup = true;
        },
        toggleStatus(item){
            const payload = {
                id: item.id,
                data:{
                    title:item['category-title'],
                    status:item.status
                }
            }
            this.editProductCategory(payload)

        },
        selectItem(item){
            if(item.length){
                this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        removeItem(id){
            this.removeProductCategory(id).then(res=>{
                if(res.status){
                    this.items = [];
                    this.getProductCategories();
                    this.$toasted.success('Категория товара успешно удалена',{
                        duration : 3000
                    });
                    this.showProductCategoryPopup = false;
                }
            })
        },
      ...mapActions({
          getProductCategories:'catalog/getProductCategories',
          showProductCategory:'catalog/showProductCategory',
          editProductCategory:'catalog/editProductCategory',
          addProductCategory: 'catalog/addProductCategory',
          removeProductCategory: 'catalog/removeProductCategory',
      }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        })
    },
}
