import {mapActions, mapGetters} from "vuex";
import showPage from "../../../mixins/showPage";

export default {
    components: {
        Header: () => import('@/components/header/index.vue'),
        leftBar: () => import('@/components/left-bar/index.vue'),
        TableOptions: () => import('@/components/table/options/index.vue'),
        Table: () => import('@/components/table/list/index.vue'),
    },
    mixins:[showPage],
    data() {
        return {
            pageId:40,
            fields: [
                {
                    key: 'id',
                    label: 'id',
                },
                {
                    key: 'sms_order_id',
                    label: 'order_id',
                },
                {
                    key: 'number_phone',
                    label: this.$t('tables.number')
                },
                {
                    key: 'mailing_message',
                    label: this.$t('tables.message')
                },
                {
                    key: 'sms_balance',
                    label: this.$t('tables.sms_balance')
                },
                {
                    key: 'sms_created_at',
                    label: this.$t('tables.sending')
                },
                {
                    key: 'sms_updated_at',
                    label: this.$t('tables.updated')
                },
                {
                    key: 'state',
                    label: this.$t('tables.state')
                },
                {
                    key: 'mailing_status',
                    label: this.$t('tables.updated')
                },
            ],
            items: [],
            stateList: [
                {
                    key: 'DELIVRD',
                    title: 'Сообщение доставлено получателю',
                },
                {
                    key: 'EXPIRED',
                    title: 'Истек срок сообщения',
                },
                {
                    key: 'DELETED',
                    title: 'Удалено оператором',
                },
                {
                    key: 'UNDELIV',
                    title: 'Не доставлено',
                },
                {
                    key: 'REJECTD',
                    title: 'Сообщение доставлено получателю',
                },
                {
                    key: 'UNKNOWN',
                    title: 'Сообщение доставлено получателю',
                },
            ],
        }

    },
    computed: {
        ...mapGetters({
            menuHide: 'config/menuHide',
            smsList: 'templates/smsList',
        })
    },
    watch: {
        smsList(e) {
            this.items = [];
            e.data.map(item => {
                const status = item.delivery_status ? item.delivery_status : '<span style="color: red;">Статус отсутствует</span>'
                let state = this.stateList.filter(itemState => itemState.key === status)
                state = state[0]
                this.items.push({
                    id: item.id,
                    sms_order_id: item.order_id,
                    number_phone: item.phone,
                    mailing_message: item.message,
                    sms_balance: item.balance,
                    sms_created_at: this.$moment(item.created_at).format('YYYY.MM.DD  HH:mm:ss'),
                    sms_updated_at: this.$moment(item.updated_at).format('YYYY.MM.DD  HH:mm:ss'),
                    mailing_status: status,
                    state: item.delivery_status,
                    stateInfo: state ? state.title : 'Состояние отсутствует',
                })
            })
        },
    },
    created() {
        this.getSmsList();
    },
    methods: {
        mailingReload(){
            this.getSmsList();
        },
        ...mapActions({
            getSmsList: 'templates/getSmsList'
        })
    }

}
