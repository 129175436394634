export default {
    components:({
        BellIcon: () => import('../../assets/icons/bell.vue'),
        MessageIcon: () => import('../../assets/icons/message.vue'),
        PhoneIcon: () => import('../../assets/icons/phone.vue'),
        CalendarIcon: () => import('../../assets/icons/calendar.vue'),
        BasketIcon: () => import('../../assets/icons/basket.vue'),
        uploadIcon: () => import('../../assets/icons/upload.vue'),
        HotIcon: () => import('../../assets/icons/hot.vue'),
    })
}
