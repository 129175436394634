<template>
  <div id="app">
    <router-view/>
    <loader v-if="loadingStatus"/>
    <exitConfirm v-if="toggleExitConfirm"></exitConfirm>
    <notification/>
    <blockedPopup v-if="blocked"/>

  </div>
</template>
<script>

import {mapGetters, mapMutations} from 'vuex';
  export default {
    components: {
      loader: () => import('./components/loader/index.vue'),
      exitConfirm: () => import('./components/popups/exit-confirm/index.vue'),
      notification: () => import('./components/popups/notification/show/index.vue'),
      blockedPopup: () => import('./components/popups/blocked/index.vue'),
    },
    data(){
      return{
        timerTime: 30,
        startTimerTime: 30,
      }
    },
    computed: {
      ...mapGetters({
        loadingStatus:'config/loadingStatus',
        toggleExitConfirm:'config/toggleExitConfirm',
        blocked:'config/blocked',
        timerToggle:'config/timerToggle',
        ordersOptions:'orders/ordersOptions',
        soundName:'config/soundName',
      }),
    },
    watch:{
      timerToggle(e){
        if(!e){
          this.timerBegin();
        }
      },
      // soundName(e){
      //   if(e){
      //     setTimeout(()=>{
      //       const audio = new Audio(require('./assets/sounds/'+e+'.mp3'))
      //       audio.play();
      //     },1000)
      //   }

    },
    created() {
      const channel = this.$pusher.subscribe('countNewOrder');
      channel.bind('countNewOrder', data => {
        this.changeOrdersLength(data)
      });
      if(this.$router.history.current.name !=='sign-in' && this.$router.history._startLocation === '/'){
          const audio = new Audio(require('./assets/sounds/LogON.mp3'))
          audio.play();
      }
      else if(this.$router.history.current.name === 'sign-in' && this.$router.history._startLocation !== '/'){
          const audio = new Audio(require('./assets/sounds/LogOFF.mp3'))
          audio.play();
      }
      // this.timerBegin();
    },
    methods:{
      // timerBegin(){
      //   if(this.timerTime >= 1 && !this.timerToggle){
      //     setTimeout(()=>{
      //       this.timerTime--;
      //       this.timerBegin();
      //     },1000)
      //   }else{
      //     if(!this.timerToggle){
      //       if(this.ordersOptions){
      //         this.getOrders(this.ordersOptions).then(()=>{
      //           this.timerTime = this.startTimerTime;
      //           this.timerBegin();
      //         })
      //       }else{
      //         this.getOrders().then(()=>{
      //           this.timerTime = this.startTimerTime;
      //           this.timerBegin();
      //         })
      //       }
      //     }else{
      //       this.timerTime = this.startTimerTime;
      //     }
      //   }
      // },
      ...mapMutations({
        toggleLoadingStatus: 'config/toggleLoadingStatus',
        changeOrdersLength: 'orders/changeOrdersLength',
      })
    }
  }
</script>
<style src="./assets/style/style.scss" lang="scss"></style>
