import {mapActions, mapGetters, mapMutations} from 'vuex';
import paginationMixin from '@/mixins/pagination/index.js'
import showPage from "../../mixins/showPage";

export default {
    components:{
        Header: () => import('@/components/header/index.vue'),
        leftBar: () => import('@/components/left-bar/index.vue'),
        Table: () => import('@/components/table/list/index.vue'),
        pagination: () => import('@/components/pagination/index.vue'),
        provisionersPopup: () => import('@/components/popups/provisioners/index.vue'),
        TableOptions: () => import('@/components/table/options/index.vue')
    },
    mixins: [paginationMixin, showPage],
    data(){
        return{
            pageId:25,
            fields:[
                {
                    key: 'id',
                    label: 'ID'
                },
                {
                    provisioner: this.$t('tables.provisioner')
                },
                {
                    name: this.$t('tables.contact_face')
                },
                {
                    provisionersPhone: this.$t('tables.mobile_phone')
                },
                {
                    email:'email',
                },
                {
                    provisionerData: this.$t('tables.added')
                },
                {
                    card_number: this.$t('tables.card_number')
                },
            ],
            items:[],
            addProvisionersPopup: false,
            selectedItem: null,
        }
    },
    computed: {
        ...mapGetters({
            menuHide:'config/menuHide',
            provisioners:'warehouse/provisioners',
        })
    },
    watch:{
        provisioners(e){
            this.items = []
            e.data.map(item=>{
                this.items.push({
                    id:item.id,
                    provisioner: item.title,
                    name:item.name,
                    provisionersPhone: item.phone,
                    email: item.email,
                    provisionerData: this.$moment(item.created_at).format('YYYY.MM.DD  HH:mm:ss'),
                    card_number: item.card_number
                });
            })
            this.res = e
        },
        page(e) {
            this.getProvisioners({page: e, per_page: this.perPage});
        },
        per_page(e) {
            this.getProvisioners({page: this.page, perpage: e});
        }
    },
    created() {
        this.toggleLoadingStatus(true);
        this.getProvisioners({page: 1, perpage: this.perPage}).then(()=>{
            this.toggleLoadingStatus(false);
        })
    },
    methods:{
        getAllItemsInTable(){
            this.selectedItem = this.items;
            this.$refs.tableComponent.getAllItemsInTable();
        },
        selectItem(item){
            if(item.length){
                this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        changePopup(item){
            this.showProvisioner(item.id)
            this.addProvisionersPopup = true;
        },
        sendProvisioner(form){
            this.addProvisioners(form).then(res=>{
                if(res.status){
                    this.$toasted.success('Поставщик вдало додани',{
                        duration : 3000
                    });
                }
                this.getProvisioners();
                this.addProvisionersPopup = false;
            })
        },
        changeProvisioner(form){
            this.editProvisioner(form).then(res=>{
                if(res.status){
                    this.$toasted.success('Поставшик вдало змінений',{
                        duration : 3000
                    });
                }
                this.getProvisioners();
                this.addProvisionersPopup = false;
            })
        },
        removeItem(id){
            this.removeProvisioner(id).then(res=>{
                if(res.status){
                    this.getProvisioners();
                    this.$toasted.success('Поставшик вдало видалений',{
                        duration : 3000
                    });
                    this.addProvisionersPopup = false;
                }
            })
        },
        ...mapActions({
            getProvisioners:'warehouse/getProvisioners',
            addProvisioners:'warehouse/addProvisioners',
            showProvisioner:'warehouse/showProvisioner',
            editProvisioner:'warehouse/editProvisioner',
            removeProvisioner:'warehouse/removeProvisioner',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        })
    }

}
