import {mapGetters, mapActions, mapMutations} from 'vuex';
import paginationMixin from '@/mixins/pagination/index.js'
import filterSearch from '@/mixins/filter-search/index.js'
import showPage from "../../../mixins/showPage";


const _ = require('lodash');

export default {
    components:{
        Header: ()=> import('@/components/header/index.vue'),
        leftBar: ()=> import('@/components/left-bar/index.vue'),
        TableOptions: ()=> import('@/components/table/options/index.vue'),
        Table: ()=> import('@/components/table/list/index.vue'),
        popup: ()=> import('@/components/popups/orders/order/index.vue'),
        popupTtn: ()=> import('@/components/popups/orders/np-ttn/index.vue'),
        popupRemember: ()=> import('@/components/popups/remember/index.vue'),
        pagination: ()=> import('@/components/pagination/index.vue'),
        popupAddProduct: ()=> import('@/components/popups/orders/add-product/index.vue'),
        popupProduct: ()=>import('@/components/popups/catalog/product/index.vue'),
        massagePopup: ()=>import('@/components/popups/sendMassage/index.vue'),
        // callHistoryPopup: ()=>import('@/components/popups/callHistory/index.vue'),
        phoneOrdersPopup: ()=>import('@/components/popups/phoneOrders/index.vue'),
        popupInfoTtn: ()=>import('@/components/popups/orders/ttn-info/index.vue'),
        confirmUnitePopup: ()=>import('@/components/popups/uniteConfirm/index.vue'),
        ttnNotification: ()=>import('@/components/popups/orders/ttn-notification/index.vue'),
        changesList: ()=>import('@/components/popups/orders/changesList/index.vue'),
        carousel: ()=> import('vue-owl-carousel'),
    },
    mixins: [paginationMixin, filterSearch, showPage],
    data(){
        return{
            pageId:9,
            ttnNotificationStatus:false,
            activeStatus:0,
            fields:[
                {
                    key:'id',
                    label:'id',
                },
                {
                    key:'order_id',
                    label:'order_id',
                },
                {
                    key:'customer_name',
                    label:'Покупатель',
                },
                {
                    key:'order_phone',
                    label:'Телефон',
                },
                {
                    key:'comment',
                    label:'Комментарий',
                },
                {
                    key:'order_value',
                    label:'сумма',
                },
                {
                    key:'product',
                    label:'Товар',
                },
                {
                    key:'payment_type',
                    label:'Оплата',
                },
                {
                    key:'shipment_type',
                    label:'Тип доставки',
                },
                {
                    key:'address',
                    label:'Адрес доставки',
                },
                {
                    key:'ttn',
                    label:'TTH',
                },
                {
                    key:'sent',
                    label:'Отправлено',
                },
                {
                    key:'ttn_status',
                    label:'TTH статус',
                },
                {
                    key:'created_at',
                    label:'Добавлено',
                },
                {
                    key:'employer',
                    label:'Сотрудник',
                },
                {
                    key:'department',
                    label:'Отдел',
                },
                {
                    key:'updated_at',
                    label:'Обновлено',
                },
                {
                    key:'site',
                    label:'Cайт',
                },
                {
                    key:'product_status',
                    label:'Статус',
                },
                {
                    key:'delivered',
                    label:'Сдано',
                },
                {
                    key: 'utm_source',
                    label: 'utm_source',
                },
                {
                    key: 'utm_medium',
                    label: 'utm_medium',
                },
                {
                    key: 'utm_campaign',
                    label: 'utm_campaign',
                },
                {
                    key:'userIp',
                    label:'ip',
                },
            ],
            items:[],
            selectedItem: null,
            showPopup: false,
            showPopupTtn: false,
            showPopupRemember: false,
            orderData: null,
            showProductPopup: false,
            showPopupInfoTtn: false,
            showMassagePopup: false,
            showHistoryPopup: false,
            showPhoneOrdersPopup: false,
            showConfirmUnitePopup: false,
            showPopupTtnNotification: false,
            newProduct: null,
            filterData:{
                type: null,
                query: null
            },
            statusesList: null,
            hidePhonesInOrders:false,
            showStatusesList: false,
            showChangesList: false,
            historyList: null,
        }
    },
    computed:{
        ...mapGetters({
            order:'orders/order',
            users: 'contacts/users',
            profile:'profile/profile',
            menuHide:'config/menuHide',
            orderAll: 'orders/orderAll',
            ordersList:'orders/ordersList',
            usersGroup:'contacts/usersGroup',
            departments:'catalog/departments',
            operatorIcons:'config/operatorIcons',
            paymentMethods:'orders/paymentMethods',
            ttnNotification:'orders/ttnNotification',
            orderStatusList:'orders/orderStatusList',
            showAddProductPopUp:'config/showAddProductPopUp',
            shipmentMethods:'orders/shipmentMethods',
        })
    },
    watch:{
        ttnNotification(e){
            this.showPopupTtnNotification = true;
            setTimeout(()=>{
                if(!e.models.length){
                    this.showPopupTtnNotification = false;
                }
            },500)
        },
        ordersList(e){
            if(isNaN(e)){
                this.getOrderListTable(e);
            }
        },
        page(){
            this.searchPayload.page = this.page
            this.searchPayload.perPage = this.perPage
            this.getOrders(this.searchPayload)
        },
        per_page(){
            this.getOrders(this.searchPayload)
        },
        usersGroup(e){
            if(this.profile && this.profile.user_group_id){
                let userGroup = e.data.filter(item=>item.id === this.profile.user_group_id)
                userGroup = userGroup[0];
                this.hidePhonesInOrders =  userGroup.hide_phones_in_orders;
                this.statusesList = userGroup.accept_to_statuses.filter(item=>item.value)
                this.statusesList.map(item=>{
                    this.orderStatusList.data.map(itemGroup=>{
                        if(item.id === itemGroup.id){
                            item.orders_count = itemGroup.orders_count
                            item.color = itemGroup.color
                        }
                    })
                })
            }
        }
    },
    created() {
        this.getUsersGroup();
        this.toggleLoadingStatus(true);
        this.getOrderStatuses().then(()=>{
            this.getDepartments().then(()=>{
                this.getPaymentMethods().then(()=>{
                    this.getUsers().then(()=>{
                        this.getOrders().then(()=>{
                            this.toggleLoadingStatus(false);
                        })
                    })
                })
            })
        });
        this.getShipmentMethods();
        setTimeout(()=>{
            this.showStatusesList = true;
        },1500)
    },

    methods:{
        openAddPopup(){
            this.showPopup = true
            this.changeOrderMutation(null);
        },
        getAllItemsInTable(){
            this.selectedItem = this.items;
            this.$refs.tableComponent.getAllItemsInTable();
        },
        timerReady(){
            let payload = {
                page: this.page,
                perpage: this.perPage
            };
            if(this.activeStatus !== 0){
                payload.order_status_id = this.activeStatus
            }
            if(this.filterData.query && this.filterData.query.length){
                payload[this.filterData.type] = this.filterData.query
            }
            this.getOrders(this.searchPayload);

        },
        removeFilterItem(index){
            this.filterList.splice(index, 1);
            this.createPayloadForSearch();
        },
        getPayloadForSearch(){
            if(this.activeStatus !== 0){
                this.searchPayload['order_status_id'] = this.activeStatus
                this.getOrders(this.searchPayload);

            }else{
                this.getOrders(this.searchPayload);

            }
        },
        setUniteOrders(){
            if(this.validationUniteOrders()){
                this.showConfirmUnitePopup = true;
            }
        },
        confirmUnite(){
            let ordersList = _.clone(this.selectedItem);
            let idChangeList = [];
            const firstOrder = ordersList.pop();
            ordersList.map(itemOrder=>{
                itemOrder.productList.map(itemProduct=>{
                    if(!firstOrder.productList.some(item => item.product_id === itemProduct.product_id)){
                        firstOrder.productList.push(itemProduct)
                    }else{
                        firstOrder.productList.map(item=>{
                            if(item.product_id === itemProduct.product_id){
                                item.quantity += itemProduct.quantity
                            }
                        })
                    }
                })
                idChangeList.push(itemOrder.id);
            });
            let payloadChangeList = {
                'orders_id': idChangeList,
                'order_status_id': 31
            }
            this.changeOrdersStatus(payloadChangeList);
            let payload = null;
            this.showOrder(firstOrder.id).then(()=>{
                payload = _.clone(this.order);
                payload.products = firstOrder.productList;
                this.changeOrder({
                    id: payload.id,
                    data:payload
                })
            })
        },
        validationUniteOrders(){
            let validate = true
            let phoneNumber = this.selectedItem[0].order_phone;
            this.selectedItem.map(item=>{
                if(phoneNumber !== item.order_phone){
                    this.$toasted.error('Не совпадают номера телефонов.',{duration : 3000})
                    validate = false
                }else if(item.product_status_id !== 1){
                    this.$toasted.error('Статусы заказов должны быть "новый"',{duration : 3000})
                    validate = false
                }
            })
            return validate
        },
        openRememberPopup(e){
            this.orderData = e.form;
            this.showPopupRemember = e.status;
        },
        openOrderChangesList(e){
            this.historyList = e;
            this.showChangesList = true;
        },
        addProduct(e){
            this.newProduct = e;
        },
        getOrderListTable(e){
            this.items = [];
            const ttnList = {
                ttn: []
            }
            e.data.map(item=>{
                let allPrice = 0;
                let number = null;
                let employer = null;
                let orderStatus = null;
                let resultPayment = null;
                let shipmentMethods = null;
                let resultDepartment = null;

                const numberBegin = item.phone.slice(0,3);
                const allProducts = [];

                item['order_products'].map(itemProduct =>{
                    allPrice += Number(itemProduct.price * itemProduct.quantity);
                    allProducts.push(itemProduct.title)
                })
                if(item.order_status_id && this.orderStatusList && this.orderStatusList.data){
                    orderStatus = this.orderStatusList.data.filter(itemStatus => itemStatus.id === item.order_status_id)
                    orderStatus = orderStatus.length ? orderStatus[0] : null;
                }
                if(this.paymentMethods && this.paymentMethods.data){
                    resultPayment = this.paymentMethods.data.filter(itemPayment => itemPayment.id === item.payment_method_id);
                    resultPayment = resultPayment[0]
                }
                if(this.shipmentMethods && this.shipmentMethods.data){
                    shipmentMethods = this.shipmentMethods.data.filter(itemShipment => itemShipment.id === item.shipment_method_id )
                    shipmentMethods = shipmentMethods[0];
                }
                if(this.departments && this.departments.data){
                    resultDepartment =  this.departments.data.filter(itemDepartment => itemDepartment.id === item.department_id);
                    resultDepartment = resultDepartment[0];
                }
                if(this.users){
                    employer = this.users.filter(itemUsers => itemUsers.id === item.employee_id)
                    employer = employer[0];
                }

                if(this.operatorIcons){
                    this.operatorIcons.map(itemOperator=>{
                        itemOperator.keys.map(itemkey => {
                            if(JSON.stringify(itemkey) === JSON.stringify(numberBegin)){
                                number = itemOperator;
                            }
                        })
                    })
                }
                this.items.push({
                    'id': item.id,
                    'order_id': item.order_id,
                    'customer_name': item.customer_name,
                    'order_phone': item.phone,
                    'comment': item.comment,
                    'site': item.site,
                    'created_at': this.$moment(item.created_at).format('YYYY-MM-DD HH:mm:ss'),
                    'updated_at':  this.$moment(item.updated_at).format('YYYY-MM-DD HH:mm:ss'),
                    'order_value': allPrice.toFixed(2),
                    'product': item.order_products[0] && item.order_products[0].title ? item.order_products[0].title : null,
                    'allProducts': allProducts,
                    'address':item.address,
                    'ttn':item.ttn,
                    'userIp':item.ip,
                    'utm_source':item.utm_source,
                    'utm_medium':item.utm_medium,
                    'utm_campaign':item.utm_campaign,
                    'product_status':orderStatus ? orderStatus.title : null,
                    'product_status_id':item.order_status_id ? item.order_status_id : null,
                    'product_color':orderStatus ? orderStatus.color : null,
                    'department':resultDepartment && resultDepartment.title ? resultDepartment.title : null,
                    'payment_type':resultPayment && resultPayment.title ? resultPayment.title : null,
                    'payment_icon':resultPayment && resultPayment.image ? resultPayment.image : null,
                    'status_open':item.status_open,
                    'user_id_open':item.user_id_open,
                    'user_login_open':item.user_login_open,
                    "productList": item.order_products,
                    "employer": employer ? employer.name : null,
                    "phoneInfo": number,
                    "shipment_type": shipmentMethods ? shipmentMethods.title : null,
                    "shipment_ico": shipmentMethods ? shipmentMethods.image : null,
                    "sms_count": item.sms_count ? item.sms_count : null,
                    "hidePhonesInOrders": this.hidePhonesInOrders,
                })
                if(item.ttn){
                    ttnList.ttn.push(item.ttn)
                }
            })
            if(!this.ttnNotificationStatus){
                this.getTtnNotification(ttnList);
                this.ttnNotificationStatus = true;
            }
            this.res = e;
        },
        getOrderFromStatus(id){
            this.ttnNotificationStatus = false;
            this.activeStatus = id;
            if(id !== 0){
                this.searchPayload['order_status_id'] = this.activeStatus;
                this.getOrders(this.searchPayload);
            }else{this.searchPayload['order_status_id'] = null;
                this.getOrders(this.searchPayload);
            }
        },
        sendOrder(item){
            this.addOrder(item).then(res=>{
                if(res.status){
                    this.items = [];
                    this.$toasted.success('Статкс успешно добавлен', {
                        duration: 3000
                    });
                    this.showPopup = false;
                    if(this.activeStatus){
                        this.getOrders(this.searchPayload);
                    }else{
                        this.getOrders(this.searchPayload);
                    }

                }else{
                    this.showErrors(res.data.errors);
                }
            });
        },
        changeOrder(item){
            this.editOrder(item).then(res=>{
                if(res.status){
                    this.items = [];
                    this.$toasted.success('Статус успешно изменен', {
                        duration: 3000
                    });
                    this.showPopup = false;
                    if(this.activeStatus){
                        this.searchPayload['order_status_id'] = this.activeStatus
                        this.getOrders(this.searchPayload);
                    }else{
                        this.getOrders(this.searchPayload);
                    }

                }else{
                    if(res.data.errors){
                        this.showErrors(res.data.errors);
                    }else if(res.data.message === "Error on updating order: No query results for model [App\\Models\\LotProduct]."){

                        this.$toasted.error('Недостаточно товара на складе', {
                            duration: 3000
                        });
                    }
                }
            });
        },
        showErrors(e){
            let key;
            for(key in e){
                this.$toasted.error(e[key], {
                    duration: 3000
                });
            }
        },
        openNpTtn(data){
            this.orderData = data.form;
            this.showPopupTtn = data.status
        },
        openInfoTtn(){
            this.showPopupInfoTtn = true;
        },
        toggleStatus(item){
            const payload = {
                id: item.id,
                data:{
                    title:item.title,
                    status:Boolean(item.status)
                }
            }
            this.editOrder(payload)
        },
        selectItem(item){
            if(item.length){
                this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        editClickItem(item){
            if(item){
                this.showOrder(item.id)
                this.showPopup = true;
            }
        },
        removeItem(id){
            this.removeOrder(id).then(res=>{
                if(res.status){
                    this.items = [];
                    this.getOrders(this.searchPayload);
                    this.$toasted.success('Статус успешно удален',{
                        duration : 3000
                    });
                    this.showPopup = false;
                }
            })
        },
        showProduct(id){
            this.getShowProduct(id)
            this.showProductPopup = true;
        },
        showMassage(result){
            this.orderData = result.form
            this.showMassagePopup = result.status;
        },
        showCallHistory(result){
            this.showHistoryPopup = result.status;
        },
        ...mapActions({
            getOrders:'orders/getOrders',
            showOrder:'orders/showOrder',
            editOrder:'orders/editOrder',
            addOrder:'orders/addOrder',
            removeOrder:'orders/removeOrder',
            getShipmentMethods:'orders/getShipmentMethods',
            getOrderStatuses:'orders/getOrderStatuses',
            getDepartments:'catalog/getDepartments',
            getPaymentMethods:'orders/getPaymentMethods',
            getShowProduct: 'catalog/showProduct',
            changeOrdersStatus: 'orders/changeOrdersStatus',
            getUsers: 'contacts/getUsers',
            getUsersGroup:'contacts/getUsersGroup',
            getTtnNotification:'orders/getTtnNotification',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
            changeOrderMutation: 'orders/changeOrder',
        })
    },
    destroyed() {
        this.changeOrderMutation(null);
    }
}

//     <audio id="SOUND-LOGON" src="http://textilelux.lp-crm.biz/sound/LogON.mp3" type="audio/mp3" preload="auto"></audio>
// <audio id="SOUND-LOGOFF" src="http://textilelux.lp-crm.biz/sound/LogOFF.mp3" type="audio/mp3" preload="auto"></audio>
// <audio id="SOUND-ERROR" src="http://textilelux.lp-crm.biz/sound/ERROR.mp3" type="audio/mp3" preload="auto"></audio>
// <audio id="SOUND-CONFIRM" src="http://textilelux.lp-crm.biz/sound/CONFIRM.mp3" type="audio/mp3" preload="auto"></audio>
// <audio id="SOUND-WARNING" src="http://textilelux.lp-crm.biz/sound/WARNING.mp3" type="audio/mp3" preload="auto"></audio>
// <audio id="SOUND-INFO" src="http://textilelux.lp-crm.biz/sound/INFO.mp3" type="audio/mp3" preload="auto"></audio>
//
// <audio id="SOUND-ALARM" src="http://textilelux.lp-crm.biz/sound/ALARM_CLOCK.mp3" type="audio/mp3" preload="auto"></audio>
// <audio id="SOUND-BUTTON-SWITCH" src="http://textilelux.lp-crm.biz/sound/BUTTON_SWITCH.mp3" type="audio/mp3" preload="auto"></audio>
// <audio id="SOUND-ACCESS-LOCKED" src="http://textilelux.lp-crm.biz/sound/ACCESS_LOCKED.mp3" type="audio/mp3" preload="auto"></audio>
// <audio id="SOUND-MESSAGE" src="http://textilelux.lp-crm.biz/sound/MESSAGE.mp3" type="audio/mp3" preload="auto"></audio>
