// import axios from 'axios'

// axios.defaults.headers = {
//     'Authorization':'Bearer ' + localStorage.getItem('token'),
//     'Accept':'application/json',
// }

const state = {
    notifications: [],
    notification: null
}

const getters = {
    notifications: state => state.notifications,
    notification: state => state.notification
}

const actions = {
    getNotifications: async({commit}) => {
        try {
            if(!localStorage.getItem('notifications')) {
                localStorage.setItem('notifications', JSON.stringify([]))
            }
            const result = await JSON.parse(localStorage.getItem('notifications'))
            commit('changeNotifications', result)
            return result
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    showNotification: async({commit, dispatch}, id) => {
        try {
            const notifications = await dispatch('getNotifications')
            const result = notifications.find( item => item.id === id)
            commit('changeNotification', result)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    addNotification: async({commit, dispatch}, payload) => { 
        try {
            const notifications = await dispatch('getNotifications')
            notifications.push({...payload, id: Math.random(0, 10000)})
            localStorage.setItem('notifications', JSON.stringify(notifications))
            commit('changeNotification', payload)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    editNotification: async({commit, dispatch}, payload) => {
        try {
            const notifications = await dispatch('getNotifications')
            // const item = notifications.find(e => e.id === payload.id)
            const index = notifications.findIndex(item => item.id === payload.id)
            notifications.splice(index, 1, {id: payload.id, ...payload})
            localStorage.setItem('notifications', JSON.stringify(notifications))
            commit('changeNotification', payload)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    removeNotification: async({commit, dispatch}, id) => {
        try {
            const notifications = await dispatch('getNotifications')
            const index = notifications.findIndex(item => item.id === id)
            notifications.splice(index, 1)
            localStorage.setItem('notifications', JSON.stringify(notifications))
            commit('changeNotifications', notifications)
        } catch (e) {
            console.log(e)
            throw e
        }
    }
}

const mutations = {
    changeNotifications(state, status) {
        state.notifications = status
    },
    changeNotification(state, status) {
        state.notification = status
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};