import {mapActions, mapGetters} from 'vuex';


export default {
    props: ['item', 'index'],
    computed:{
      ...mapGetters({
          profile:'profile/profile',
      })
    },
    methods: {
        openItemPopup() {
            if(this.item.is_done || this.item.expired) {
                return
            }
           this.showNotification(this.item.id)
           this.$emit('openPopup', this.item.id)
        },
        toggleStatus(item) {
           this.editNotification(item)
        },
        removeItem(id){
            this.$emit('removeItem', id);
        },
        ...mapActions({
            showNotification: 'notification/showNotification',
            editNotification: 'notification/editNotification',
        })
    }
}