<template>
    <div class="logs-links-page">
        <div class="pass" v-if="!successVarificationPassword">
            <input type="password" v-model="password">
            <b-button variant="success" size="sm" @click="varificationPassword()">enter</b-button> 
        </div>
        <div class="logs" v-else>
            <ul class="log-links">
                <li>
                    <router-link :to="{name:'logList'}">site logs</router-link>
                </li>
                <li>
                    <router-link :to="{name:'log1сList'}">1c logs</router-link>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    export default{
        data(){
            return{
                password:'',
                successVarificationPassword: false
            }
        },
        methods:{
            varificationPassword(){
                if(this.password === '0973757727'){
                    this.successVarificationPassword = true
                    this.$toasted.success('Success enter!',{
                        duration: 3000
                    })
                }else{
                    this.successVarificationPassword = false
                    this.$toasted.error('Wrong password',{
                        duration: 3000
                    })
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .logs-links-page{
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
    }
    .pass{
        background: #ccc;
        padding: 25px;
        border-radius: 5px;
        input{
            border-radius: 5px;
            padding: 5px;
            margin-right: 15px;
        }
    }
</style>