import {required} from 'vuelidate/lib/validators'
import {mapActions, mapGetters, mapMutations} from 'vuex';

export default {
    props: ['popupType', 'selectedItem'],
    data() {
        return {
            form: {
                title: '',
                description: '',
                sms_text: '',
                sms_enabled: 0,
            },
        }
    },

    validations: {
        form: {
            title: {
                required
            },
        }
    },
    computed: {
        ...mapGetters({
            shipmentMethod:'orders/shipmentMethod',
        }),
    },
    watch:{
        shipmentMethod(e){
            if(e){
                this.form.title = e.title;
                this.form.description = e.description;
                this.form.sms_text = e.sms_text;
                this.form.sms_enabled = Boolean(e.sms_enabled);
                this.form.image = e.image;
                this.imageSrc = e.image;
                this.imageFile = e.image;
            }
        }
    },
    methods: {
        changeFile(e) {
            if(!e.target.files[0]) {
                return
            }
            new Promise((resolve, reject) => {
                const reader = new FileReader()
                if(!e.target.files[0]) {
                    console.log('Select file please!')
                }
                reader.readAsDataURL(e.target.files[0])
                reader.onload = () => resolve(this.imageSrc = reader.result.toString())
                reader.onerror = error => reject(error)
            })
        },
        removeImage() {
            this.imageSrc = null
            this.form.images = null
            this.imageFile = null
        },
        closePopup() {
            this.$emit('closePopup')
        },
        sendChanges() {
            if(this.imageFile){
                this.storeProductImage(this.imageFile).then( res => {
                    this.form.image = res.base_url_image;
                    this.methodSendForms();
                })

            }else{
                this.methodSendForms();
            }
        },
        methodSendForms(){
            if(this.shipmentMethod){
                const payload = {
                    id: this.shipmentMethod.id,
                    data:this.form
                }
                this.$emit('changeTypeDelivery', payload)
            }else{
                this.$emit('sendTypeDelivery', this.form)
            }
        },
        ...mapActions({
            storeProductImage: 'catalog/storeProductImage',
        }),
        ...mapMutations({
            changeShipmentMethod: 'orders/changeShipmentMethod'
        }),
    },
    destroyed() {
        this.changeShipmentMethod(null);
    }
}
