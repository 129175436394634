export default {
    data: function() {
        return {
            page: 1,
            per_page: 50,
            res: {}
        }
    },
    methods: {
        changePerPage(count) {
            this.per_page = count
        },
        changePage(num) {
            this.page = num
        },
        toLastPage(last) {
            this.page = last
        },
        toFirstPage(first) {
            this.page = first
        },
        nextPage(num) {
            this.page = num
        },
        prevPage(num) {
            this.page = num
        }
    }
}
