import axios from 'axios';
import qs from "qs";

axios.defaults.headers = {
    'Authorization':'Bearer ' + localStorage.getItem('token'),
    'Accept':'application/json',
}

const state = {
    smsTemplates: null,
    smsTemplate: null,
    smsBalance: null,
    smsStatus: null,
    smsList: null,
    callList: null,
    callLinkData: null,
    mainCard: null,
};

const getters ={
    smsTemplates: state => state.smsTemplates,
    smsTemplate: state => state.smsTemplate,
    smsBalance: state => state.smsBalance,
    smsStatus: state => state.smsStatus,
    smsList: state => state.smsList,
    callList: state => state.callList,
    callLinkData: state => state.callLinkData,
    mainCard: state => state.mainCard,
};

const actions = {
    sendSMSTemplate: async({commit}, payload) => {
      try{
          const result = await axios.post('/sms/send', payload);

          commit('changeSmsStatus', result.data.models);
          return{
              result: true,
              data: result.data
          }
      }catch (e){
          return {
              result: false,
              data: e.response.data
          }
      }
    },
    getSmsBalance: async({commit}) => {
      try{
          const result = await axios.post('/sms/balance');

          commit('changeSmsBalance', result.data.models);
          return{
              result: true,
              data: result.data
          }
      }catch (e){
          return {
              result: false,
              data: e.response.data
          }
      }
    },
    getSmsTemplates: async({commit}, options) => {
        let url = '/sms_templates'
        if(options) {
            url = `/sms_templates?page=${options.page}&per_page=${options.per_page}`
        }
        try {
            const result = await axios.get(url)
            commit('changeSmsTemplates', result.data.models)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    showSmsTemplate: async({commit}, id) => {
        try {
            const result = await axios.get('/sms_templates/'+id)
            commit('changeSmsTemplate', result.data.models)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    addSmsTemplate: async({commit}, payload) => {
        try {
            const result = await axios.post('/sms_templates', payload)
            commit('changeSmsTemplate', result.data.models)
            return{
                status: true,
                data: result.data
            }
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    editSmsTemplate: async({commit}, payload) => {
        try {
            const result = await axios.put('/sms_templates/'+payload.id, payload)
            commit('changeSmsTemplate', result.data.models)
            return{
                status: true,
                data: result.data
            }
        } catch (e) {
            console.log(e)
            // throw e
        }
    },
    removeSmsTemplate: async({commit}, id) => {
        try{
            const result = await axios.post('/sms_templates/'+id,{
                "_method" : "DELETE"
            });
            commit('changeSmsTemplate', result.data.models);
        }catch (e){
            if(e.response.status === 302) {
                return {
                    status: true,
                    data: e.data
                }
            }
        }
    },
    getSmsList: async ({commit}) =>{
        try{
            const result = await axios.get('/sms/index')
            commit('changeSmsList', result.data.models)
        }catch (e){
            return{
                status: false,
                data: e.response.data
            }
        }
    },
    getCallList: async ({commit}, options) =>{
        try{
            const result = await axios.get('/calls', {
                params: options,
                paramsSerializer: (params) =>{
                return qs.stringify(params);
                }
            })
            commit('changeCallList', result.data.models)
        }catch (e){
            return{
                status: false,
                data: e.response.data
            }
        }
    },
    getCallLink: async ({commit}, binotel_id) =>{
        try{
            const result = await axios.post('/getCallRecord', {
                binotel_id: binotel_id
            })
            commit('changeCallLink', { result: result.data, binotel_id})
            return{
                status: true,
                data: result.data
            }
        }catch (e){
            return{
                status: false,
                data: e.response.data
            }
        }
    },
    getMainCard: async ({commit}) =>{
        try{
            const result = await axios.get('/get_main_card')
            commit('changeMainCard',   result.data.models)
            return{
                status: true,
                data: result.data
            }
        }catch (e){
            return{
                status: false,
                data: e.response.data
            }
        }
    },

}

const mutations ={
    changeSmsTemplates(state, status) {
        state.smsTemplates = status;
    },
    changeSmsTemplate(state, status) {
        state.smsTemplate = status;
    },
    changeSmsBalance(state, status){
        state.smsBalance = status;
    },
    changeSmsStatus(state, status){
        state.smsStatus = status;
    },
    changeSmsList(state, status){
        state.smsList = status;
    },
    changeCallList(state, status){
        state.callList = status;
    },
    changeCallLink(state, data){
        state.callLinkData = data;
    },
    changeMainCard(state, data){
        state.mainCard = data;
    },

};



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
