import axios from 'axios';

const state = {
    auth: localStorage.getItem('token') ? localStorage.getItem('token') : null,
    signIn: null,
    isAuthentificated: false,
};
axios.defaults.headers = {
    'Accept':'application/json',
}

const getters ={
    auth: state => state.auth,
    signIn: state => state.signIn,
    isAuthentificated: state => state.isAuthentificated,
};

const actions = {
    postSignIn: async ({commit}, payload) => {
      try{
          const result = await axios.post('/auth/login',payload);
          commit('changeAuthInStatus', result.data);
          return {
              status: true,
              data: result.data
          };
      }catch(e){
          return {
              status: false,
              data: e.response.data
          };
        }
    },
    postSignUp: async ({commit}, payload) => {
      try{
          const result = await axios.post('/auth/signup',payload);
          commit('changeAuthStatus', result.data);
          return {
              status: true,
              data: result.data
          };
      }catch(e){
          return {
              status: false,
              data: e.response.data
          };
        }
    },
    logout: async ({commit, state}) => {
      try{
          const result = await axios.get('/auth/logout',{
                  headers: {
                      Authorization: `Bearer ${state.auth}`,
                  }}
              );
          return {
              status: true,
              data: result.data
          }
      }catch(e){
          commit('changeLogout', e);
          console.log(e);
          return {
              status: false,
              data: e.response.data
          };
        }
    }
}



const mutations ={
    changeAuthStatus(state, status){
        state.signIn = status;
    },
    changeAuthInStatus(state, status){
        if(status.access_token){
            localStorage.setItem('token', status.access_token);
            state.auth = status.access_token;
        }
    },
    changeLogout(state, result){
        console.log('logout - ', result);
        localStorage.removeItem('token');
        state.changeAuthInStatus = false;
    }
};



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
