import {mapGetters, mapActions, mapMutations} from 'vuex';
import Header from '@/components/header/index.vue'
import leftBar from '@/components/left-bar/index.vue'
import TableOptions from '@/components/table/options/index.vue'
import Table from '@/components/table/list/index.vue'
import productPricePopup from '@/components/popups/catalog/priceCategory/index.vue'
import pagination from '@/components/pagination/index.vue'
import paginationMixin from '@/mixins/pagination/index.js'

export default {
    components:{
        Header,
        leftBar,
        TableOptions,
        Table,
        productPricePopup,
        pagination
    },
    mixins: [paginationMixin],
    data(){
        return{
            fields:[
                {
                    key: 'id',
                    label: 'ID'
                },
                {
                    key:'title',
                    label:'Название',
                },
                {
                    key:'salePrice',
                    label:'Ціна продажу',
                },
            ],
            items:[],
            selectedItem: null,
            showPriceCategoryPopup: false,
        }
    },
    computed:{
        ...mapGetters({
            menuHide:'config/menuHide',
            priceCategories:'catalog/priceCategories',
        })
    },
    watch:{
        priceCategories(e){
            this.items = []
            e.data.map(item=>{
                this.items.push({id: item.id, 'title': item.title, 'salePrice': item.sell_price})
            })
            this.res = e
        },
        page(e) {
            this.getPriceCategories({page: e, per_page: this.per_page});
        },
        per_page(e) {
            this.getPriceCategories({page: this.page, per_page: e});
        }
    },

    created() {
        this.toggleLoadingStatus(true);
        this.getPriceCategories({page: 1, per_page: this.per_page}).then(()=>{
            this.toggleLoadingStatus(false);
        })
    },
    methods:{
        getAllItemsInTable(){
            this.selectedItem = this.items;
            this.$refs.tableComponent.getAllItemsInTable();
        },
        sendPriceCategory(item){
            this.addPriceCategory(item).then(res=>{
                if(res.status){
                    this.items = [];
                    this.$toasted.success('Категория товара успешно добавлена', {
                        duration: 3000
                    });
                    this.showPriceCategoryPopup = false;
                    this.getPriceCategories();
                }
            });
        },
        changePriceCategory(item){
            this.editPriceCategory(item).then(res=>{
                if(res.status){
                    this.items = [];
                    this.$toasted.success('Категория товара успешно обновлена', {
                        duration: 3000
                    });
                    this.showPriceCategoryPopup = false;
                    this.getPriceCategories();
                }
            });
        },
        selectItem(item){
            if(item.length){
                this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        editClickPriceCategoty(item){
            this.showPriceCategory(item.id)
            this.showPriceCategoryPopup = true;
        },
        removeItem(id){
            this.removePriceCategory(id).then(res=>{
                if(res.status){
                    this.items = [];
                    this.getPriceCategories();
                    this.$toasted.success('Категория товара успешно удалена',{
                        duration : 3000
                    });
                    this.showPriceCategoryPopup = false;
                }
            })
        },
        ...mapActions({
            getPriceCategories:'catalog/getPriceCategories',
            showPriceCategory:'catalog/showPriceCategory',
            editPriceCategory:'catalog/editPriceCategory',
            addPriceCategory:'catalog/addPriceCategory',
            removePriceCategory:'catalog/removePriceCategory',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        })
    },
}
