import {mapGetters, mapActions, mapMutations} from "vuex";
import showPage from "../../../mixins/showPage";

export default {
    mixins:[showPage],
    data(){
      return{
          pageId:37,
          currency_value: 0,
          fields: [
              {
                  key: 'id',
                  label: 'id'
              },
              {
                  key: 'card',
                  label: 'card'
              },
              {
                  key: 'full_name',
                  label: this.$t('tables.full-name')
              },
              {
                  key: 'password',
                  label: 'password'
              },
          ],
          items:[],
          selectedItem: null,
          showPopup: false
      }
    },
    components: {
        Header: () => import('@/components/header/index.vue'),
        leftBar: () => import('@/components/left-bar/index.vue'),
        CardPopup: () => import('@/components/popups/card/index.vue'),
        TableOptions: () => import('@/components/table/options/index.vue'),
        Table: () => import('@/components/table/list/index.vue'),
    },
    computed: {
        ...mapGetters({
            menuHide: 'config/menuHide',
            cardList: 'rate/cardList',
            users:'contacts/users',
        })
    },
    watch:{
        cardList(e){
            if(e && e.models && e.models.data.length){
                e.models.data.map(item =>{
                    this.items.push({
                        id: item.id ? item.id : '0',
                        card: item.card ? item.card : '',
                        full_name: item.full_name ? item.full_name : '',
                        password: item.password ? item.password : '',
                    })
                })
            }
        }
    },
    created() {
        this.getUsers().then(()=>{
            this.getCardList();
        });
    },
    methods:{
        changePopup(item){
            this.getCard(item.id)
            this.showPopup = true;
        },
        closePopup(){
            this.showPopup = false;
        },
        ...mapActions({
            getCardList:'rate/getCardList',
            getUsers:'contacts/getUsers',
        }),
        ...mapMutations({
            getCard:'rate/getCard'
        })
    },

}
