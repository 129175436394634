import {mapGetters} from "vuex";

export default {
    data(){
         return{
             pageShow:false,
         }
    },
    computed:{
      ...mapGetters({
          profile:'profile/profile'
      })
    },
    watch:{
        profile(){
            this.statusShowPage();
        }
    },
    created() {
        if(this.profile){
            this.statusShowPage();
        }
    },
    methods:{
        statusShowPage(){
            const actualPage = this.profile.group.accept_to_menu_items.filter(item=> item.id === this.pageId)[0];
            if(actualPage.value){
                this.pageShow = true
            }else{
                this.$router.push({path: '/desktop'});
            }
        },
    }
}
