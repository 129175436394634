<template>
  <div class="logs">
    <div class="logs__title"> 
      <h3>1с logs</h3>
    </div>

    <a class="link-back" href="/">← back to crm</a>

    <div class="logs__search">
        <div class="logs__search-label">
          <p>request_url:</p>
          <span><input type="text" v-model="search.request_url"></span>
        </div>
        <div class="logs__search-label">
          <p>request_type:</p>
          <span>
            <v-select
                v-model="search.request_type"
                class="select-input ml-0"
                :reduce="item => item.title"
                :options="requestTypeList"
                label="title"
            ></v-select>
          </span>
        </div>
        <div class="logs__search-label">
          <p>request_query:</p>
          <span><input type="text" v-model="search.request_query"></span>
        </div>
        <div class="logs__search-label">
          <p>data_type: </p>
          <span>
            <v-select
                v-model="search.data_type"
                class="select-input ml-0"
                :options="dataTypeList"
                :reduce="item => item.title"
                label="title"
            ></v-select>
          </span>
        </div>
        <div class="logs__search-label">
          <p>data:</p>
          <span><input type="text" v-model="search.data"></span>
        </div>
        <div class="logs__search-label"> 
          <b-button
                size="sm"
                class="btn auth__btn p-1 pl-4 pr-4 active"
                variant="primary" 
                @click="searchWitchPyaload()"
            >SEARCH
            </b-button>
        </div>
        <div class="logs__search-label"> 
          <b-button
                size="sm"
                class="btn active p-1 pl-4 pr-4"
                variant="danger" 
                @click="clearData()">CLEAR
            </b-button>
        </div>
    </div>

    <div class="logs__list">
      <div class="logs__item" v-for="(item, index) in list" :key="index">
        <div class="logs__item-label">
          <p>
            id
          </p>
          <span>
            {{item.id}}
          </span>
        </div>
        <div class="logs__item-label">
          <p>
            request url
          </p>
          <span>
            {{item.request_url}}
          </span>
        </div>
        <div class="logs__item-label">
          <p>
            request type
          </p>
          <span>
            {{item.request_type}}
          </span>
        </div>
        <div class="logs__item-label">
          <p>
            data type
          </p>
          <span>
            {{item.data_type}}
          </span>
        </div>
        <div class="logs__item-label">
          <p>
            updated at
          </p>
          <span>
            {{item.updated_at}}
          </span>
        </div>
        <div class="logs__item-data">
          <p>
            data:
          </p>
          <span v-html="showDataType(item.data)">
            
          </span>
        </div> 
      </div>
    </div> 
  </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
  data(){
    return{
      list: null,
      search:{
        request_url: '',
        request_type: '',
        request_query: '',
        data_type: '',
        data: '',
      },
      requestTypeList:[
        {
          id: 0,
          title:'setProductLots',
        },
        {
          id: 1,
          title:'setBulkProductLots'
        },
        {
          id: 2,
          title: 'getOrders'
        },
        {
          id: 3,
          title: 'confirmOrders'
        },
        {
          id: 4,
          title: 'updateOrdersFrom1C'
        },
        {
          id: 5,
          title: 'getOrderStatusList'
        },
        {
          id: 6,
          title: 'getOrderInvoice'
        },
        {
          id: 7,
          title: 'getOrderStatusList'
        },
        {
          id: 8,
          title:'getProductReserves'
        },
        {
          id: 9,
          title:'getProductList'
        },
        {
          id: 10,
          title:'createProductFrom1C'
        },
        {
          id: 11,
          title:'getPaymentList'
        },
        {
          id: 12,
          title:'getShipmentList'
        },
      ],
      dataTypeList:[
        {
          id: 0,
          title:'order',
        },
        {
          id: 1,
          title: 'orderStatuses'
        },
        {
          id: 2,
          title: 'orderShipment'
        },
        {
          id: 3,
          title: 'orderPayment'
        },
        {
          id: 4,
          title: 'product'
        }, 
      ],
    }
  },
  computed:{
    ...mapGetters({
      logList:'config/logList'
    })
  },
  created() {
    this.getLogs();
  },
  watch:{
    logList(e){
      this.list = e.models.data
    }
  },
  methods:{
    showDataType(data){
      const dataParse = JSON.parse(data)  
      if(dataParse.length === 1 && typeof dataParse[0] === 'string'){
       return dataParse[0]
      }else if(dataParse.length >= 1 && typeof dataParse[0] === 'object'){
        let dataTemplate = `<p>all conut: ${dataParse.length}</p>`;
        dataParse.map(item => {
         dataTemplate += 
          '<div style="padding: 10px; background:#eaeaea; margin-bottom: 5px">'+
            `<p style="font-size: 13px; margin-bottom: 2px;">id: ${item.id}</p>`+
            `<p style="font-size: 13px; margin-bottom: 2px;">title: ${item.title}</p>`+
            `<p style="font-size: 13px; margin-bottom: 2px;">sku: ${item.sku}</p>`+
            `<p style="font-size: 13px; margin-bottom: 2px;">isbn: ${item.isbn}</p>`+
            `<p style="font-size: 13px; margin-bottom: 2px;">sale price: ${item.sale_price}</p>`+
            `<p style="font-size: 13px; margin-bottom: 2px;">discount price: ${item.discount_price}</p>`+
            `<p style="font-size: 13px; margin-bottom: 2px;">upsell manager motivation: ${item.upsell_manager_motivation}</p>`+
            `<p style="font-size: 13px; margin-bottom: 2px;">status: ${item.status}</p>`+
            `<p style="font-size: 13px; margin-bottom: 2px;">sort: ${item.sort}</p>`+
            `<p style="font-size: 13px; margin-bottom: 2px;">minimum: ${item.minimum}</p>`+
            `<p style="font-size: 13px; margin-bottom: 2px;">quantity: ${item.quantity}</p>`+
          '</div>'
        })
          return dataTemplate
      }else if(dataParse.length === undefined && dataParse[0] === undefined && Object.keys(dataParse).length >= 1){

        let dataTemplate = `<p>all conut: ${Object.keys(dataParse).length}</p>`;
        Object.keys(dataParse).map(item => {
         dataTemplate += 
          '<div style="padding: 10px; background:#eaeaea; margin-bottom: 5px">'+
            `<p style="font-size: 13px; margin-bottom: 2px;">id: ${item}</p>`+
            `<p style="font-size: 13px; margin-bottom: 2px;">title: ${dataParse[item]}</p>`+
          '</div>'
        })
        return dataTemplate
      }
       return dataParse;
    },
    searchWitchPyaload(){
      let payload = {};
      Object.keys(this.search).map(item=>{
        if(this.search[item].length){
          payload[item] = this.search[item];
        }
      }) 
      this.getLogs(payload);
    },
    clearData(){
      this.search.request_url = '';
      this.search.request_type = '';
      this.search.request_query = '';
      this.search.data_type = '';
      this.search.data = '';
      this.getLogs();
    },
    ...mapActions({
      getLogs:'config/getOnecLogs'
    })
  }
}
</script>
<style lang="scss" scoped>
.logs{
  background-color: #f3f3f3;
  padding: 15px;
  &__search{
    display: flex;
    flex-wrap: wrap;
  }
  &__search-label{
    display: flex;
    padding: 15px;
    p{
      padding-right: 10px;
    }
    input{
      padding: 2px 5px;
    }
  }
  &__title{
    font-size: 18px;
    margin-bottom: 15px;
  }
  &__dates{
    margin-bottom: 10px;
    p{
      font-size: 16px;
      margin-bottom: 5px;
    }
  }
  &__dates-list{
    display: flex;
  }
  &__dates-item{
    margin-right: 10px;
    font-size: 12px;
    &:after{
      content: ',';
    }
  }
  &__date{
    margin-bottom: 10px;
  }
  &__list{
    padding: 0px;
    display: flex;
    flex-direction: column;
  }
  &__list-title{
    font-size: 18px;
    margin-bottom: 20px;
  }
  &__item{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 5px;
    background-color: #cccccc;
  }
  &__item-label{
    display: flex;
    margin: 2px 10px;
    min-width: 25%;
    align-items: center;
    p{
      margin-right: 10px;
      margin-bottom: 0;
      font-size: 14px;
      &:after{
        content: ':';
      }
    }
    span{
      font-size: 12px;
    }
  }
  &__item-data{
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 2px 10px;
    font-size: 14px;
    span{
      overflow: auto;
      padding: 15px;
      max-height: 500px;
      background: #fff7f7;
    }
  }
  &__data-list{
    background-color: #cccccc;
  }
  .link-back{
    font-size: 14px;
    margin-bottom: 15px;
    display: block;
    color: rgb(126, 116, 116)
  }
  .btn{
    max-height: 28px;
    line-height: 12px;
    padding-top: 5px !important; 
  }
}
</style>
