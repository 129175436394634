import axios from 'axios';

axios.defaults.headers = {
    'Accept':'application/json',
}

const state = {
    profile: null,
};

const getters ={
    profile: state => state.profile,

};

const actions = {
    getProfile: async ({commit}) => {
        try{
            const result = await axios.get('/auth/user');
            commit('changeProfile', result.data);
            return {
                status: true,
                data: result.data
            };
        }catch(e){
            console.log(e);
            if(e.response.status === 401){
                localStorage.removeItem('token');
                state.changeAuthInStatus = false;
            }
            return {
                status: false,
                data: e.response.data
            };
        }
    },
}



const mutations ={
    changeProfile(state, status){
        state.profile = status;
    },
};



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
